import moment from "moment";

export function formatDateToSend(value: string) {
  if (!value) return "";

  moment.locale("pt-BR");
  const x = moment(value, "DD-MM-YYYY");
  const result = x.format("YYYY-MM-DD");
  return result;
}

export function formatFromISODate(value: string) {
  if (!value) return "";

  return moment(value).format("DD/MM/YYYY");
}

export function formatStringToDate(value: string) {
  if (!value) return "";

  moment.locale("pt-BR");
  const x = moment(value, "DD-MM-YYYY");
  return x;
}

export function formatDateTimeView(value: string) {
  if (!value) return "";

  return moment(value).format("DD/MM/YY - HH:mm:ss");
}
