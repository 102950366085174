import { Button, Modal, Table } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import { TailSpin } from "react-loader-spinner";

import PageLoader from "../../../../components/Common/Loader";
import { formatDateTimeView } from "../../../../helpers/DateTime";
import { TableSyncDataStyles } from "../../../../styles/generic";
import { ModalGenericStyles } from "../../../../styles/modalsGeneric";

interface ModalLogDetailsProps {
  loadingLogs: boolean;
  loadingModal: boolean;
  logsHistory: any;
  show: any;
  onHide: () => void;
  children: any;
}

export const ModalLogDetails = ({
  loadingLogs,
  loadingModal,
  logsHistory,
  show,
  onHide,
  children,
}: ModalLogDetailsProps) => {
  const HistoricSection = () => {
    return (
      <section className="mt-2 pl-3">
        <h4 className="mb-2">
          <strong>Histórico de registros</strong>
        </h4>
        {loadingLogs ? (
          <TailSpin
            color="#2155ba"
            height={50}
            width={50}
            wrapperStyle={{
              margin: "auto",
              placeContent: "center",
            }}
          />
        ) : logsHistory && logsHistory.length > 0 ? (
          <TableSyncDataStyles
            className="dataCustomTable"
            style={{ height: "100%", padding: 0 }}
          >
            <Table
              className="mt-3 table-list"
              striped
              borderless
              hover
              responsive
            >
              <thead>
                <tr className="title-table">
                  <th className="border-radius-left">Criado em</th>
                  <th className="border-radius-right">Mensagem</th>
                </tr>
              </thead>

              <tbody>
                {logsHistory?.map((item) => {
                  return (
                    <tr
                      id="tr-linha"
                      key={item.logId}
                      className="table-values cursor-pointer"
                    >
                      <td>
                        {formatDateTimeView(
                          item.creationDate?.toLocaleString()
                        )}
                      </td>
                      <td>{item.message}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableSyncDataStyles>
        ) : (
          <>
            {" "}
            <p>Nenhum histórico de registros</p>
          </>
        )}
      </section>
    );
  };

  return (
    <ModalGenericStyles show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <h3>Resultado da Integração</h3>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => onHide()}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {loadingModal ? (
          <PageLoader />
        ) : (
          <>
            {children}
            <HistoricSection />
          </>
        )}
      </Modal.Body>
    </ModalGenericStyles>
  );
};
