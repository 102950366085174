import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { BsTable, BsListCheck } from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzPagination, {
  PaginationProps,
} from "../../components/Common/BrainzPagination/BrainzPagination";
import PageLoader from "../../components/Common/Loader";
import { showToast } from "../../components/Common/Toast";
import { formatDateTimeView } from "../../helpers/DateTime";
import { handleGetPageSize } from "../../helpers/functions";
import { BrainzIntegrationFile } from "../../interfaces/BrainzIntegrationFile";
import ApiInterface from "../../services/ApiInterface";
import { InitialHeaderStyles } from "../../styles/initialStyles";
import "./styles.css";

function ManualIntegration() {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [queryParams, setQueryParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const [forceReload, setForceReload] = useState(1);
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [sheets, setSheets] = useState<
    Array<BrainzIntegrationFile> | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [filesUri, setFilesUri] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  useEffect(() => {
    loadPage();

    function loadPage() {
      setLoading(true);
      ApiInterface.get(`/IntegrationFile/FilesUri`).then((response) => {
        setFilesUri(response.data.result);
      });
      ApiInterface.get(`/IntegrationFile/${institutionId}/GetAll${queryParams}`)
        .then((response) => {
          setPaging(response.data.paging);
          setSheets(response.data.result);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [queryParams, forceReload]);

  function importFile(integrationFileId: string) {
    ApiInterface.patch(
      `/IntegrationFile/${integrationFileId}/ToImportStatus`
    ).then((response) => {
      if (response.status === 200)
        showToast("success", "Adicionado à fila com sucesso");
    });
  }

  async function uploadFile(file: any) {
    const fileUploaded = file;

    const formData = new FormData();
    formData.append("File", fileUploaded);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    ApiInterface.patch(
      `/IntegrationFile/${institutionId}/Upload`,
      formData,
      config
    ).then((response) => {
      if (response.status === 200) {
        showToast("success", "Adicionado com sucesso");
        setForceReload(forceReload + 1);
        setFileUpload("");
      }
    });
  }

  async function removeFile(integrationFileId: string) {
    ApiInterface.delete(`/IntegrationFile/${integrationFileId}/Delete`).then(
      (response) => {
        if (response.status === 200) {
          showToast("success", "Removido com sucesso");
          setForceReload(forceReload + 1);
        }
      }
    );
  }
  return (
    <div>
      <InitialHeaderStyles>
        <Row className="headingStickySubPages">
          <Col md={12} style={{ padding: 0 }}>
            <h2>Integração Manual</h2>
          </Col>
          <br />
          <br />
          <Col md={12} style={{ padding: 0 }}>
            <h3>Inclua arquivos com os dados que deseja integrar.</h3>
          </Col>
        </Row>

        <section className="templatesContainer pt-0 mt-0">
          <span>Templates de Planilha</span>
          <div className="pl-2 d-block">
            <BsTable className="mr-2" size={20} />
            <a href={filesUri + "/Template.xlsx"} target="__blank">
              Template
            </a>
          </div>
          <div className="pl-2 d-block">
            <BsListCheck className="mr-2" size={20} />
            <a href={filesUri + "/Orientacoes.xlsx"} target="__blank">
              Orientações para preenchimento
            </a>
          </div>
        </section>
        <div className="provisionador-subtitle">
          Faça o upload de uma nova planilha:
        </div>
        <Form>
          <Form.Control
            type="file"
            value={fileUpload}
            onChange={(e: any) => uploadFile(e.target.files[0])}
          />
        </Form>

        {loading ? (
          <PageLoader />
        ) : (
          <div className="MITableContainer">
            <div className="provisionador-subtitle">Planilhas publicadas</div>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nome do arquivo</th>
                  <th>Data de Cadastro</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {sheets && sheets.length > 0 ? (
                  sheets?.map((sheet) => {
                    return (
                      <tr key={sheet.id}>
                        <td>{sheet.fileName}</td>
                        <td>
                          {formatDateTimeView(
                            sheet.creationDate?.toLocaleString()
                          )}
                        </td>
                        <td>
                          {sheet.status === 1
                            ? "Não importado"
                            : sheet.status === 2
                            ? "Pendente"
                            : sheet.status === 3
                            ? "Importado com sucesso"
                            : "Importado com erros"}
                        </td>
                        <td className="actionsTD">
                          <Button
                            size="sm"
                            className="m-1"
                            onClick={() => window.open(sheet.url, "_blank")}
                          >
                            Download
                          </Button>
                          {sheet.status === 1 && (
                            <>
                              <Button
                                className="m-1"
                                size="sm"
                                onClick={() => removeFile(sheet.id)}
                              >
                                Excluir
                              </Button>
                              <Button
                                className="m-1"
                                size="sm"
                                onClick={() => {
                                  importFile(sheet.id);
                                  setForceReload(forceReload + 1);
                                }}
                              >
                                Importar
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5}>Nenhum resultado encontrado.</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {paging && (
              <BrainzPagination
                setQueryParams={setQueryParams}
                paging={paging}
              />
            )}
          </div>
        )}
      </InitialHeaderStyles>
    </div>
  );
}

export default ManualIntegration;
