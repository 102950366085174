import { useEffect, useState } from "react";
import { Button, Col, Form, Nav, Row, Table } from "react-bootstrap";
import {
  BsArrowLeft,
  BsCheckCircle,
  BsDownload,
  BsEye,
  BsInfoCircle,
  BsXCircle,
  BsXOctagon,
} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import BrainzPagination, {
  PaginationProps,
} from "../../../components/Common/BrainzPagination/BrainzPagination";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import Search from "../../../components/Common/Input/Search";
import PageLoader from "../../../components/Common/Loader";
import NoResults from "../../../components/Common/NoResults";
import { showToast } from "../../../components/Common/Toast";
import {
  formatDateTimeView,
  formatFromISODate,
} from "../../../helpers/DateTime";
import { handleGetPageSize } from "../../../helpers/functions";
import ApiInterface from "../../../services/ApiInterface";
import UnitApiInterface from "../../../services/UnitApiInterface";
import UserApiInterface from "../../../services/UserApiInterface";
import {
  FiltersStyleComponentUser,
  TableSyncDataStyles,
} from "../../../styles/generic";
import { ItemLogsNavBar } from "../../../styles/initialStyles";
import { ModalExportLogs } from "../../SyncData/Modals/ModalExportData/ExportLogs";
import {
  BrainzUserViewModel,
  IntegrationLogDetailsViewModel,
  BrainzTeamViewModel,
  LogViewModel,
  BrainzUnitViewModel,
} from "../interfaces";
import { ModalLogDetails } from "./Modals/ModalLogDetails";
import { LogDetailsStyles } from "./style";

export default function LogsDetails() {
  const location: any = useLocation();
  const navigate = useNavigate();
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [queryPaginationParams, setQueryPaginationParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [loading, setLoading] = useState(true);
  const [logDetails, setLogDetails] = useState<
    Array<IntegrationLogDetailsViewModel> | undefined
  >();
  const [errorFilter, setErrorsFilter] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [errorCounter, setErrorCounter] = useState(0);
  const [successCounter, setSuccessCounter] = useState(0);
  const [itemDetails, setItemDetails] = useState<
    BrainzUserViewModel | undefined
  >();
  const [itemDetailsUnit, setItemDetailsUnit] = useState<
    BrainzUnitViewModel | undefined
  >();
  const [itemDetailsTeam, setItemDetailsTeam] = useState<
    BrainzTeamViewModel | undefined
  >();
  const [logsHistory, setLogsHistory] = useState<
    Array<LogViewModel> | undefined
  >();

  const [showUser, setShowUser] = useState(false);
  const handleModalDetailUserClose = () => setShowUser(false);
  const handleModalDetailUserShow = () => setShowUser(true);

  const [showUnit, setShowUnit] = useState(false);
  const handleModalDetailUnitClose = () => setShowUnit(false);
  const handleModalDetailUnitShow = () => setShowUnit(true);

  const [showTeam, setShowTeam] = useState(false);
  const handleModalDetailTeamClose = () => setShowTeam(false);
  const [showExportDataModal, setShowExportDataModal] =
    useState<boolean>(false);

  const [loadingLogs, setLoadingLogs] = useState(true);
  const [loadingModal, setLoadingModal] = useState(true);

  const integrationLogId = location?.state?.integrationLogId;
  const logEntityType = location?.state?.logEntityType;
  const startDate = location?.state?.startDate;
  const integrationTypeName = location?.state?.integrationTypeName;
  const integrationTypeEntityName = location?.state?.integrationTypeEntityName;
  const entityType = location?.state?.integrationTypeEntityType;

  function ClearFilters() {
    setSearchFilter("");
  }

  function queryPagination() {
    let result: string;
    if (queryPaginationParams) result = `${queryPaginationParams}`;
    else result = "";
    return result;
  }

  function queryFilters() {
    let query = "";
    if (errorFilter) query = query += "&errorFilter=true&";
    else query = query += "&errorFilter=false&";
    if (searchFilter !== "") query = query += "&searchFilter=" + searchFilter;
    if (entityType !== 0) query = query += "&entityTypeFilter=" + entityType;
    return query;
  }

  useEffect(() => {
    if (!location?.state) {
      setLoading(true);
      return ReturnLogs();
    }
    loadPage();
  }, [queryPaginationParams, errorFilter, searchFilter]);

  useEffect(() => {
    gotoPageOne();
  }, [errorFilter, searchFilter]);

  useEffect(() => {
    if (!location?.state) {
      setLoading(true);
      return ReturnLogs();
    }
    loadInitialErrorCounters();
  }, []);

  function gotoPageOne() {
    if (paging) {
      const paginationProps: PaginationProps = {
        currentRecord: paging.currentRecord,
        currentPage: 1,
        pageSize: paging.pageSize,
        recordsOnPage: paging.recordsOnPage,
        totalRecords: paging.totalRecords,
        totalPages: paging.totalPages,
      };
      setPaging(paginationProps);
      setQueryPaginationParams(`?page=1&pageSize=${handleGetPageSize()}`);
    }
  }

  function loadPage() {
    setLoading(true);

    if (queryPaginationParams)
      ApiInterface.get(
        `/Log/GetLogByIntegration/Integration/${institutionId}/${integrationLogId}/${logEntityType}${queryPagination()}${queryFilters()}`
      )
        .then((response) => {
          if (errorFilter) {
            setErrorCounter(response.data.paging.totalRecords);
          } else {
            setSuccessCounter(response.data.paging.totalRecords);
          }
          setPaging(response.data.paging);
          setLogDetails(response.data.result.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
  }

  function loadInitialErrorCounters() {
    setLoading(true);
    if (queryPaginationParams) {
      ApiInterface.get(
        `/Log/GetLogByIntegration/Integration/${institutionId}/${integrationLogId}/${logEntityType}${queryPagination()}&errorFilter=true&entityTypeFilter=${entityType}`
      )
        .then((response) => {
          setErrorCounter(response.data.paging.totalRecords);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  }

  function ReturnLogs() {
    navigate(`/logs?id=${institutionId}`);
  }

  function loadLogsHistory(item) {
    setLoadingLogs(true);
    const id = item?.identifier;
    const type = item?.type;

    if (!id || !type) {
      setLoadingLogs(false);
      return showToast(
        "error",
        "Não foi possível identificar o id ou tipo da requisição."
      );
    }

    return ApiInterface.get(
      `/Log/GetLogByIdentifier/Identifier/${institutionId}/${id}/${type}`
    )
      .then((response) => {
        // setPaging(response.data.paging);
        if (response?.data?.result?.result) {
          setLogsHistory(response.data.result.result);
        }
        setLoadingLogs(false);
      })
      .catch((error) => {
        setLoadingLogs(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro ao buscar histórico de logs => ", error);
      });
  }

  const GoDetails = (item: any) => {
    const identifier = item?.identifier;
    const institutionId = item?.institutionId;

    if (
      item.entityType === 12 ||
      item.entityType === 13 ||
      item.entityType === 14 ||
      item.entityType === 15 ||
      item.entityType === 17
    ) {
      return showToast("info", "Não existem detalhes de ensalamento.");
    }
    if (
      item.entityType === 9 ||
      item.entityType === 10 ||
      item.entityType === 1 ||
      item.entityType === 4
    ) {
      setLoadingModal(true);
      setLoadingLogs(true);
      handleModalDetailUserShow();

      return UserApiInterface.get(
        `/User/${institutionId}/GetByIdentifier/${identifier}`
      )
        .then((response) => {
          if (response.data.result !== null) {
            setItemDetails(response.data.result);
            loadLogsHistory(response.data.result);
          } else {
            showToast("warn", "Erro não encontrado.");
          }
          setLoading(false);
          setLoadingModal(false);
        })
        .catch((error) => {
          setLoading(false);
          setLoadingModal(false);

          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
    if (item.entityType === 8) {
      setLoadingModal(true);
      setLoadingLogs(true);
      handleModalDetailUnitShow();

      return UnitApiInterface.get(
        `/Unit/${institutionId}/GetByIdentifier/${identifier}`
      )
        .then((response) => {
          if (response.data.result !== null) {
            setItemDetailsUnit(response.data.result);
            loadLogsHistory(response.data.result);
          } else {
            showToast("error", "Erro não encontrado.");
          }
          setLoading(false);
          setLoadingModal(false);
        })
        .catch((error) => {
          setLoading(false);
          setLoadingModal(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  };
  return (
    <>
      <LogDetailsStyles className="container-fluid ml-0 pl-0">
        <section className="headingStickySubPagesLogs">
          <Col className="pl-0">
            <Row className="row pl-0 ml-0">
              <Col md={4}>
                <Button className="button-return-logs" onClick={ReturnLogs}>
                  <BsArrowLeft color="#2155BA" size={20} />
                  Voltar para Logs de Execução
                </Button>
              </Col>
              <Col md={8} style={{ textAlign: "center" }}>
                <Row>
                  <Col md={4}>
                    <h3>Data da integração</h3>
                  </Col>
                  <Col md={3}>
                    <h3>Tipo de Integração</h3>
                  </Col>
                  <Col md={3}>
                    <h3>Entidade</h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    {formatDateTimeView(startDate?.toLocaleString())}
                  </Col>
                  <Col md={3}>
                    <h2>{integrationTypeName}</h2>
                  </Col>
                  <Col md={3}>
                    <h2>{integrationTypeEntityName}</h2>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="ml-auto pl-0 ml-0 nav-item">
              <ItemLogsNavBar className="pl-0 ml-0">
                <Nav
                  variant="pills"
                  defaultActiveKey="/home"
                  className="px-0 mt-2 mb-0 pl-0 ml-0"
                  style={{ background: "" }}
                >
                  <Nav.Item className="mx-0">
                    <Nav.Link
                      active={errorFilter === false}
                      onClick={() => setErrorsFilter(false)}
                      className="pt-3 pb-2"
                    >
                      Integrados com Sucesso ({successCounter})
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mx-1">
                    <Nav.Link
                      active={errorFilter === true}
                      onClick={() => setErrorsFilter(true)}
                      className="pt-3 pb-2"
                    >
                      Registros com Erros ({errorCounter})
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </ItemLogsNavBar>
            </Row>
          </Col>
        </section>

        <FiltersStyleComponentUser className="w-100 search-filter mt-2">
          <Col md={10}>
            <Search
              setSearchFilter={setSearchFilter}
              searchFilter={searchFilter}
              placeholder="Buscar por ID ou Nome"
            />
          </Col>
          {searchFilter !== "" && (
            <div className="mr-4">
              <CustomTooltip
                type="action-button"
                text="Limpar filtro"
                placement="bottom"
                buttonContent={
                  <Button className="clear-filter " onClick={ClearFilters}>
                    <BsXCircle
                      className="bi bi-x-circle"
                      color="#A56300"
                      size={24}
                    />
                  </Button>
                }
              />
            </div>
          )}
          <CustomTooltip
            type="action-button"
            text="Exportar logs"
            placement="bottom"
            buttonContent={
              <Button
                aria-label="Exportar logs"
                className="button-update"
                onClick={() => setShowExportDataModal(true)}
              >
                <BsDownload className="bi bi-x-circle" color="" size={20} />
              </Button>
            }
          />
        </FiltersStyleComponentUser>

        <Row className="">
          {loading ? (
            <PageLoader />
          ) : logDetails && logDetails.length > 0 ? (
            <>
              <TableSyncDataStyles className="dataCustomTable">
                <Table
                  className="mt-3 table-list"
                  striped
                  borderless
                  hover
                  responsive
                >
                  <thead>
                    <tr className="title-table">
                      <th className="border-radius-left">Identificador</th>
                      <th>Nome</th>
                      <th className="border-radius-right">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logDetails?.map((item) => {
                      return (
                        <tr
                          id="tr-linha"
                          key={item.logId}
                          className="table-values cursor-pointer"
                        >
                          <td>{item.identifier}</td>
                          <td>{item.message}</td>
                          <td>
                            <CustomTooltip
                              type="action-button"
                              text="Acessar detalhes"
                              placement="bottom"
                              buttonContent={
                                <div
                                  role="button"
                                  className="action-item"
                                  onClick={() => GoDetails(item)}
                                >
                                  {errorFilter ? (
                                    <BsEye color="#0F7EAA" size={24} />
                                  ) : (
                                    <BsInfoCircle color="#0F7EAA" size={24} />
                                  )}
                                </div>
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Col md={12}>
                  {paging && (
                    <BrainzPagination
                      setQueryParams={setQueryPaginationParams}
                      paging={paging}
                    />
                  )}
                </Col>
              </TableSyncDataStyles>
            </>
          ) : (
            <>
              <Row style={{ margin: "auto" }}>
                <NoResults clearFilterFunction={ClearFilters} />
              </Row>
            </>
          )}
        </Row>
      </LogDetailsStyles>

      {itemDetails !== undefined && (
        <ModalLogDetails
          loadingLogs={loadingLogs}
          loadingModal={loadingModal}
          logsHistory={logsHistory}
          show={showUser}
          onHide={handleModalDetailUserClose}
        >
          <Form>
            <Form.Group as={Row} className=" my-0">
              <Col sm={4}>
                <Form.Label column sm={12} className=" my-0 py-0">
                  Resultado
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  {itemDetails.active === true ? (
                    <>
                      <BsCheckCircle
                        className="mr-2"
                        color="#008555"
                        size={24}
                      />
                      Sucesso
                    </>
                  ) : (
                    <>
                      <BsXOctagon className="mr-2" color="#A56300" size={24} />
                      Com erros
                    </>
                  )}
                </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Label column className=" my-0 py-0">
                  Criado em
                </Form.Label>
                <Form.Label column className=" my-0 py-0">
                  <h2>
                    {formatDateTimeView(
                      itemDetails.creationDate?.toLocaleString()
                    )}
                  </h2>
                </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Label column className=" my-0 py-0">
                  Tipo de Pessoa
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>
                    {itemDetails.type === 4 && "Aluno"}
                    {itemDetails.type === 5 && "Professor"}
                    {itemDetails.type === 1 && "Administrativo"}
                    {itemDetails.type === 3 && "Coordenador"}
                  </h2>
                </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className=" my-0 py-0">
              <Col sm={5}>
                <Form.Label column className=" my-0 py-0">
                  ID
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>{itemDetails.identifier}</h2>
                </Form.Label>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className=" my-0 pb-2">
              <Col sm={11}>
                <Form.Label column className=" my-0 py-0">
                  Nome
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>
                    {itemDetails.displayName === ""
                      ? "--"
                      : itemDetails.displayName}
                  </h2>
                </Form.Label>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className=" my-0 py-0">
              <Col sm={11}>
                <Form.Label column sm={12} className=" my-0 py-0">
                  E-mail
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>{itemDetails.email === "" ? "--" : itemDetails.email}</h2>
                </Form.Label>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className=" my-0 pb-2">
              <Col sm={12}>
                <Form.Label column className=" my-0 py-0">
                  Matrícula
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>
                    {itemDetails.registration === ""
                      ? "--"
                      : itemDetails.registration}
                  </h2>
                </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className=" my-0 pb-2">
              <Col sm={5}>
                <Form.Label column className=" my-0 py-0">
                  Data de Nascimento
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>
                    {itemDetails.birthday === null
                      ? "--"
                      : formatFromISODate(
                          itemDetails.birthday?.toLocaleString()
                        )}
                  </h2>
                </Form.Label>
              </Col>
              <Col sm={6}>
                <Form.Label column className=" my-0 py-0">
                  Celular
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>
                    {itemDetails.cellphone === ""
                      ? "--"
                      : itemDetails.cellphone}
                  </h2>
                </Form.Label>
              </Col>
            </Form.Group>
          </Form>
        </ModalLogDetails>
      )}
      {itemDetailsUnit !== undefined && (
        <ModalLogDetails
          loadingLogs={loadingLogs}
          loadingModal={loadingModal}
          logsHistory={logsHistory}
          show={showUnit}
          onHide={handleModalDetailUnitClose}
        >
          <Form>
            <Form.Group as={Row} className=" my-0 pt-3">
              <Col sm={6}>
                <Form.Label column sm={12} className=" my-0 py-0">
                  Resultado
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  {itemDetailsUnit.active === true ? (
                    <>
                      <BsCheckCircle
                        className="mr-2"
                        color="#008555"
                        size={24}
                      />
                      Sucesso
                    </>
                  ) : (
                    <>
                      <BsXOctagon className="mr-2" color="#A56300" size={24} />
                      Com erros
                    </>
                  )}
                </Form.Label>
              </Col>
              <Col sm={6}>
                <Form.Label column className=" my-0 py-0">
                  Criado em
                </Form.Label>
                <Form.Label column className=" my-0 py-0">
                  <h2>
                    {formatDateTimeView(
                      itemDetailsUnit.creationDate?.toLocaleString()
                    )}
                  </h2>
                </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className=" my-0 py-0">
              <Col sm={5}>
                <Form.Label column className=" my-0 py-0">
                  ID
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>{itemDetailsUnit.identifier}</h2>
                </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className=" my-0 pb-2">
              <Col sm={11}>
                <Form.Label column className=" my-0 py-0">
                  Nome
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>
                    {itemDetailsUnit.name === "" ? "--" : itemDetailsUnit.name}
                  </h2>
                </Form.Label>
              </Col>
            </Form.Group>
          </Form>
        </ModalLogDetails>
      )}
      {itemDetailsTeam !== undefined && (
        <ModalLogDetails
          loadingLogs={loadingLogs}
          loadingModal={loadingModal}
          logsHistory={logsHistory}
          show={showTeam}
          onHide={handleModalDetailTeamClose}
        >
          <Form>
            <Form.Group as={Row} className=" my-0 pt-3">
              <Col sm={6}>
                <Form.Label column sm={12} className=" my-0 py-0">
                  Resultado
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  {itemDetailsTeam.active === true ? (
                    <>
                      <BsCheckCircle
                        className="mr-2"
                        color="#008555"
                        size={24}
                      />
                      Sucesso
                    </>
                  ) : (
                    <>
                      <BsXOctagon className="mr-2" color="#A56300" size={24} />
                      Com erros
                    </>
                  )}
                </Form.Label>
              </Col>
              <Col sm={6}>
                <Form.Label column className=" my-0 py-0">
                  Criado em
                </Form.Label>
                <Form.Label column className=" my-0 py-0">
                  <h2>
                    {formatDateTimeView(
                      itemDetailsTeam.creationDate?.toLocaleString()
                    )}
                  </h2>
                </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className=" my-0 py-0">
              <Col sm={5}>
                <Form.Label column className=" my-0 py-0">
                  ID
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>{itemDetailsTeam.identifier}</h2>
                </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className=" my-0 pb-2">
              <Col sm={11}>
                <Form.Label column className=" my-0 py-0">
                  Nome
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>
                    {itemDetailsTeam.name === "" ? "--" : itemDetailsTeam.name}
                  </h2>
                </Form.Label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className=" my-0 pb-2">
              <Col sm={11}>
                <Form.Label column className=" my-0 py-0">
                  Unidade
                </Form.Label>
                <Form.Label column sm={12} className=" my-0 py-0">
                  <h2>
                    {itemDetailsTeam.unit !== undefined
                      ? "--"
                      : itemDetailsTeam.unit}
                  </h2>
                </Form.Label>
              </Col>
            </Form.Group>
          </Form>
        </ModalLogDetails>
      )}

      <ModalExportLogs
        showModal={showExportDataModal}
        onClose={() => setShowExportDataModal(false)}
        data={{ institutionId, integrationLogId, logEntityType, queryFilters }}
      />
    </>
  );
}
