import { useContext, useEffect, useState } from "react";
import { Button, Form, Row, Col, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BsExclamationCircle,
  BsInfoCircle,
  BsXCircle,
  BsXOctagon,
  BsCheckCircle,
  BsPencil,
  BsX,
  BsCheck2,
  BsFileRichtext,
  BsDownload,
  BsMortarboard,
} from "react-icons/bs";
import { TailSpin } from "react-loader-spinner";
import Cookies from "universal-cookie";
import { toPattern } from "vanilla-masker";

import BrainzComboBox from "../../components/Common/BrainzComboBox";
import BrainzPagination, {
  PaginationProps,
} from "../../components/Common/BrainzPagination/BrainzPagination";
import CustomTooltip from "../../components/Common/CustomTooltip";
import Search from "../../components/Common/Input/Search";
import PageLoader from "../../components/Common/Loader";
import ConfirmModal from "../../components/Common/Modals/ConfirmModal";
import NoResults from "../../components/Common/NoResults";
import { showToast } from "../../components/Common/Toast";
import { ProfileContext } from "../../contexts/ProfileContext";
import { SyncDataFilterContext } from "../../contexts/SyncDataFilterContext";
import {
  formatDateTimeView,
  formatDateToSend,
  formatFromISODate,
} from "../../helpers/DateTime";
import {
  handleGetPageSize,
  renderStatusTableData,
} from "../../helpers/functions";
import { useValidation, feedBack } from "../../hooks/Validation";
import ApiInterface from "../../services/ApiInterface";
import UserApiInterface from "../../services/UserApiInterface";
import {
  FiltersStyleComponentUser,
  TableSyncDataStyles,
} from "../../styles/generic";
import { ModalGenericStyles } from "../../styles/modalsGeneric";
import { PaginationContainer } from "./Modals/ModalLogsHistory/styles";
import ModalViewTeamUser from "./Modals/ModalViewTeamUser";
import { ActiveModalContext } from "./SyncDataContexts/ActiveModalContext";
import "../../styles/tableCustomStyles.css";

export interface BrainzUserViewModel {
  id: string;
  identifier: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  password: string;
  birthday: string;
  cellphone: string;
  department: string;
  jobTitle: string;
  document: string;
  registration: string;
  isStudent: boolean;
  isTeacher: boolean;
  isAdministrative: boolean;
  isAssistant: boolean;
  isCoordinator: boolean;
  creationDate: Date;
  active: boolean;
  type: number;
  serviceId: string;
}

export interface BrainzUserPayload {
  id?: string;
  identifier: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  password: string;
  birthday?: string;
  cellphone: string;
  department: string;
  jobTitle: string;
  document: string;
  registration: string;
}

export interface BrainzScreenUserPayload {
  payload: BrainzUserPayload;
  types: Array<number>;
}
export interface BrainzUserSettingsViewModel {
  id: string;
  userType: number;
  integrateBirthday: boolean;
  integrateCellphone: boolean;
  integrateDepartment: boolean;
  integrateJobTitle: boolean;
  integrateDocument: boolean;
  integrateRegistration: boolean;
  defaultEmail: number;
  defaultPassword: number;
  fixedPassword: string;
}
export interface LogViewModel {
  tableName: string;
  institutionId: string;
  logId: string;
  identifier: string;
  integrationId: string;
  type: number;
  code: string;
  message: string;
  integrationType: number;
  creationDate: string;
  operationType: number;
}

export default function SyncDataUser(props: any) {
  const { updateUser, setUpdateUser, userSettings, setUserSettings } =
    useContext(ActiveModalContext);

  const { configurationProfile } = useContext(ProfileContext);

  const {
    setActiveFilterSaved,
    setSearchFilterSaved,
    setUserTypeSaved,
    setCurrentTab,
  } = useContext(SyncDataFilterContext);

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [loading, setLoading] = useState(true);
  const [loadingLogs, setLoadingLogs] = useState(true);
  const [loadingModal, setLoadingModal] = useState(true);

  const [t] = useTranslation("common");
  const [itemActive, setItemActive] = useState<boolean>();
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();
  const [id, setId] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [birthday, setBirthday] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [department, setDepartment] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [document, setDocument] = useState("");
  const [registration, setRegistration] = useState("");
  const [userType, setUserType] = useState<number>(4);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [pagingLogs, setPagingLogs] = useState<PaginationProps | undefined>();
  const [users, setUsers] = useState<Array<BrainzUserViewModel> | any>();
  const [filterUserType, setFilterUserType] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [buttonClearFilters, setButtonClearFilters] = useState(false);
  const [show, setShow] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [itemDetails, setItemDetails] = useState<
    BrainzUserViewModel | undefined
  >();
  const [buttonEdit, setButtonEdit] = useState(false);
  const [logsHistory, setLogsHistory] = useState<
    Array<LogViewModel> | undefined
  >();

  const [logHistoryId, setLogHistoryId] = useState<any>(null);

  const [queryPaginationParams, setQueryPaginationParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const [queryPaginationParamsLogs, setQueryPaginationParamsLogs] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const handleModalDetailUserClose = () => {
    setShow(false);
    setButtonEdit(false);
  };

  const handleModalHistoryUser = () => {
    setShowHistoryModal(!showHistoryModal);
  };

  const handleModalDetailUserShow = () => setShow(true);

  const [showActiveUser, setShowActiveUser] = useState(false);
  const handleModalActiveUserClose = () => setShowActiveUser(false);
  const handleModalActiveUserShow = () => setShowActiveUser(true);

  const [activeModalViewTeam, setActiveModalViewTeam] = useState(false);
  const [toDeleteItem, setToDeleteItem] = useState<string>("");
  const [toReactiveItem, setToReactiveItem] = useState<
    BrainzUserViewModel | undefined
  >();

  function queryPagination() {
    let result: string = "";
    if (queryPaginationParams) {
      const splitted = queryPaginationParams?.split("&");
      if (splitted) {
        result = `${splitted[0]}&pageSize=${handleGetPageSize()}`;
      }
    }
    return result;
  }

  function queryPaginationLogs() {
    let result: string = "";
    if (queryPaginationParamsLogs) {
      const splitted = queryPaginationParamsLogs?.split("&");
      splitted[0] = splitted[0].replace("?", "&");
      if (splitted) {
        result = `${splitted[0]}&pageSize=${handleGetPageSize()}`;
      }
    }
    return result;
  }

  function queryFilters() {
    let query = "";
    if (searchFilter !== "") query = query += "&search=" + searchFilter;

    if (activeFilter === "true") query = query += "&activeFilter=true";
    if (activeFilter === "false") query = query += "&activeFilter=false";
    if (activeFilter === "") query = query += "";
    if (filterUserType === "") query = query += "";
    if (filterUserType === "1") query = query += "&userType=1";
    if (filterUserType === "4") query = query += "&userType=4";
    if (filterUserType === "5") query = query += "&userType=5";
    if (filterUserType === "3") query = query += "&userType=3";
    if (query !== "") setButtonClearFilters(true);
    else setButtonClearFilters(false);
    return query;
  }

  function handleActiveOnClick(item: BrainzUserViewModel | any) {
    setItemActive(item.active);
    setUserType(item.type);
    setToDeleteItem(item.id);
    setToReactiveItem(item);
    handleModalActiveUserShow();
  }

  function deleteData() {
    handleModalActiveUserClose();
    setLoading(true);
    const payload: Array<string> = [toDeleteItem];
    return UserApiInterface.delete(
      `/User/${institutionId}/${userType}/Inactive`,
      { data: payload }
    )
      .then((response) => {
        if (response.status === 200) {
          const errors = response.data.result[0].errors;
          if (!errors || errors.length === 0) {
            showToast("info", "Concluído com sucesso.");
            setUpdateUser(true);
            setUpdateUser(false);
            setLoading(false);
          } else {
            setLoading(false);
            showToast("error", t("errors." + errors[0].code));
          }
        } else {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro => ", error);
      });
  }

  function reactiveData() {
    handleModalActiveUserClose();
    setLoading(true);
    const payload: Array<any> = [
      {
        id: toReactiveItem?.id,
        identifier: toReactiveItem?.identifier,
        firstName: toReactiveItem?.firstName,
        lastName: toReactiveItem?.lastName,
        displayName: toReactiveItem?.displayName,
        email: toReactiveItem?.email,
        password: "",
        birthday: toReactiveItem?.birthday,
        cellphone: toReactiveItem?.cellphone ?? "",
        department: toReactiveItem?.department ?? "",
        jobTitle: toReactiveItem?.jobTitle ?? "",
        document: toReactiveItem?.document ?? "",
        registration: toReactiveItem?.registration,
      },
    ];

    return UserApiInterface.patch(
      `/User/${institutionId}/${userType}/Reactive`,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          const errors = response.data.result[0].errors;
          if (!errors || errors.length === 0) {
            showToast("info", "Concluído com sucesso.");
            setUpdateUser(true);
            setUpdateUser(false);
            setLoading(false);
          } else {
            setLoading(false);
            showToast("error", t("errors." + errors[0].code));
          }
        } else {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro => ", error);
      });
  }

  function ClearFilters() {
    setFilterUserType("");
    setActiveFilter("");
    setSearchFilter("");
    setButtonClearFilters(false);
  }

  function loadUserSettings(userType: number) {
    ApiInterface.get(`/UserIntegrationSettings/${institutionId}/GetAll`)
      .then((response) => {
        const userSettingsList: BrainzUserSettingsViewModel[] =
          response.data.result;
        const userSettingsItem: BrainzUserSettingsViewModel =
          userSettingsList.filter((x) => x.userType === userType)[0];
        setUserSettings(userSettingsItem);
        setLoadingModal(false);
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro ao buscar UserIntegrationSettings => ", error);
        setLoadingModal(false);
      });
  }

  const GoDetails = (item: any) => {
    setItemDetails(undefined);
    setLoading(true);
    setLoadingModal(true);
    setUserSettings(undefined);
    loadUserSettings(item.type);

    setItemDetails(item);
    handleModalDetailUserShow();
    setLoading(false);
  };

  const GoDetailsHistory = (item: any) => {
    handleModalHistoryUser();
    setLogHistoryId(item.identifier);
    loadLogsHistory(item, item.identifier);
  };

  function handleViewTeam(item: any) {
    setItemDetails(item);
    setActiveModalViewTeam(true);
  }

  function handleDisplayNameOnChange(value: string) {
    setDisplayName(value);
    setValidationFormField("displayName", value);
  }

  function handleBirthdayOnChange(value: string) {
    const masked = toPattern(value, "99/99/9999");
    setBirthday(masked);
    setValidationFormField("birthday", masked);
  }

  function handleCellphoneOnChange(value: string) {
    const masked = toPattern(value, "(99) 99999-9999");
    setCellphone(masked);
    setValidationFormField("cellphone", masked);
  }

  function handleDepartmentOnChange(value: string) {
    setDepartment(value);
    setValidationFormField("department", value);
  }

  function handleJobTitleOnChange(value: string) {
    setJobTitle(value);
    setValidationFormField("jobTitle", value);
  }

  function handleDocumentOnChange(value: string) {
    setDocument(value);
    setValidationFormField("document", value);
  }

  function handleRegistrationOnChange(value: string) {
    setRegistration(value);
    setValidationFormField("registration", value);
  }

  function handleUpdateOnClick(itemDetails: any) {
    setButtonEdit(true);
    validationForm.current = {};
    setNewValidationErrors({});
    setId(itemDetails.id);
    setIdentifier(itemDetails.identifier);
    setFirstName(itemDetails.firstName);
    setLastName(itemDetails.lastName);
    setDisplayName(itemDetails.displayName);
    setEmail(itemDetails.email);
    setPassword(itemDetails.password);
    setBirthday(formatFromISODate(itemDetails.birthday));
    setCellphone(itemDetails.cellphone);
    setDepartment(itemDetails.department);
    setJobTitle(itemDetails.jobTitle);
    setDocument(itemDetails.document);
    setRegistration(itemDetails.registration);
  }

  function handleCancelUpdate() {
    setButtonEdit(false);
  }

  function handleSaveData() {
    if (!id) {
      createData();
    } else {
      updateData();
    }
    function createData() {
      setButtonEdit(false);
      const birthdayValue = formatDateToSend(birthday);
      setLoading(true);
      const payload: Array<BrainzUserPayload> = [
        {
          identifier,
          firstName,
          lastName,
          displayName,
          email,
          password,
          birthday: birthdayValue !== "" ? birthdayValue : undefined,
          cellphone,
          department,
          jobTitle,
          document,
          registration,
        },
      ];

      UserApiInterface.post(
        `/User/${institutionId}/${userType}/Insert`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            const errors = response.data.result[0].errors;
            if (!errors || errors.length === 0) {
              showToast("info", "Concluído com sucesso.");
              setLoading(false);
              setButtonDisable(false);
            } else {
              setLoading(false);
              setButtonDisable(false);
              showToast("error", t("errors." + errors[0].code));
            }
          } else {
            setLoading(false);
            setButtonDisable(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
          }
        })
        .catch((error) => {
          setLoading(false);
          setButtonDisable(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }

    function updateData() {
      setButtonEdit(false);
      handleModalDetailUserClose();
      setLoading(true);
      const birthdayValue = formatDateToSend(birthday);
      const payload: Array<BrainzUserPayload> = [
        {
          id,
          identifier,
          firstName,
          lastName,
          displayName,
          email,
          password,
          birthday: birthdayValue !== "" ? birthdayValue : undefined,
          cellphone,
          department,
          jobTitle,
          document,
          registration,
        },
      ];

      return UserApiInterface.put(
        `/User/${institutionId}/${itemDetails?.type}/Update`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            const errors = response.data.result[0].errors;
            if (!errors || errors.length === 0) {
              showToast("info", "Concluído com sucesso.");
              GoDetails(response.data.result[0].value);
              //setBirthday(formatFromISODate(response.data.result.value.birthday))
              setButtonDisable(false);
              setUpdateUser(true);
              loadLogsHistory(itemDetails, identifier);
              setLogHistoryId(identifier);
              setLoading(false);
            } else {
              setLoading(false);
              setButtonDisable(false);
              showToast("error", t("errors." + errors[0].code));
            }
          } else {
            setLoading(false);
            setButtonDisable(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
          }
        })
        .catch((error) => {
          setLoading(false);
          setButtonDisable(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  }

  function loadLogsHistory(
    item: BrainzUserViewModel | undefined,
    identifier: string
  ) {
    if (queryPaginationParamsLogs) {
      setLoadingLogs(true);

      const url = `/Log/GetUserLog/Institution/${institutionId}/${item?.type}?useridentifier=${identifier}${queryPaginationLogs()}`;

      return ApiInterface.get(url)
        .then((response) => {
          setPagingLogs(response.data.paging);
          setLogsHistory(response.data.result.result);
          setLoadingLogs(false);
        })
        .catch((error) => {
          setLoadingLogs(false);
          console.error(
            "Erro ao requisitar histórico de logs do usuário. Erro => ",
            error
          );
        });
    }
  }

  useEffect(() => {
    function loadPage() {
      setLoading(true);
      if (queryPaginationParams)
        UserApiInterface.get(
          `/User/${institutionId}/List${queryPagination()}${queryFilters()}`
        )
          .then((response) => {
            setPaging(response.data.paging);
            setUsers(response.data.result);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
            console.error("Erro => ", error);
          });
    }

    loadPage();
  }, [
    queryPaginationParams,
    searchFilter,
    activeFilter,
    filterUserType,
    updateUser,
  ]);

  useEffect(() => {
    function gotoPageOne() {
      if (paging) {
        const paginationProps: PaginationProps = {
          currentRecord: paging.currentRecord,
          currentPage: 1,
          pageSize: paging.pageSize,
          recordsOnPage: paging.recordsOnPage,
          totalRecords: paging.totalRecords,
          totalPages: paging.totalPages,
        };
        setPaging(paginationProps);
        setQueryPaginationParams(`?page=1&pageSize=${handleGetPageSize()}`);
      }
    }

    gotoPageOne();
  }, [
    searchFilter,
    activeFilter,
    filterUserType,
    buttonClearFilters,
    updateUser,
  ]);

  useEffect(() => {
    function gotoPageOne() {
      if (pagingLogs) {
        const paginationProps: PaginationProps = {
          currentRecord: pagingLogs.currentRecord,
          currentPage: 1,
          pageSize: pagingLogs.pageSize,
          recordsOnPage: pagingLogs.recordsOnPage,
          totalRecords: pagingLogs.totalRecords,
          totalPages: pagingLogs.totalPages,
        };
        setPagingLogs(paginationProps);
        setQueryPaginationParamsLogs(`?page=1&pageSize=${handleGetPageSize()}`);
      }
    }
    gotoPageOne();
  }, []);

  useEffect(() => {
    if (!itemDetails) return; // evita chamadas desnecessárias
    loadLogsHistory(itemDetails, logHistoryId);
  }, [queryPaginationParamsLogs]);

  return (
    <>
      {configurationProfile && (
        <FiltersStyleComponentUser className="w-100 search-filter">
          <Col md={5}>
            <Form.Group>
              <div className="search-filter">
                <Search
                  setSearchFilter={setSearchFilter}
                  searchFilter={searchFilter}
                  placeholder="Buscar por Tipo, Identificador, Nome ou E-mail"
                />
              </div>
            </Form.Group>
          </Col>

          <Col md={2}>
            <Form.Group>
              <BrainzComboBox
                setValue={(val) => setActiveFilter(val)}
                value={activeFilter}
                idField="id"
                valueField="name"
                placeholder="Status"
                options={[
                  { name: "Ativo", value: "true" },
                  { name: "Inativo", value: "false" },
                ]}
                search={false}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <BrainzComboBox
              setValue={(val) => setFilterUserType(val)}
              value={filterUserType}
              idField="id"
              valueField="name"
              placeholder="Tipo de Pessoa"
              search={false}
              options={[
                { name: "Administrativo", value: "1" },
                { name: "Aluno", value: "4" },
                { name: "Coordenador", value: "3" },
                { name: "Professor", value: "5" },
              ]}
            />
          </Col>
          {buttonClearFilters && (
            <Col md="auto">
              <CustomTooltip
                type="action-button"
                text="Limpar filtros"
                placement="bottom"
                buttonContent={
                  <Button className="clear-filter" onClick={ClearFilters}>
                    <BsXCircle
                      className="bi bi-x-circle"
                      color="#A56300"
                      size={24}
                    />
                  </Button>
                }
              />
            </Col>
          )}

          <Col md="auto">
            <CustomTooltip
              type="action-button"
              text="Exportar dados"
              placement="bottom"
              buttonContent={
                <Button
                  aria-label="Exportar dados"
                  variant="outline-primary"
                  onClick={() => {
                    setActiveFilterSaved(activeFilter);
                    setSearchFilterSaved(searchFilter);
                    setUserTypeSaved(filterUserType);
                    setCurrentTab("users");
                    props.setActiveModal(55);
                  }}
                >
                  <BsDownload className="bi bi-x-circle" color="" size={20} />
                </Button>
              }
            />
          </Col>
        </FiltersStyleComponentUser>
      )}
      <Row className="">
        {loading ? (
          <PageLoader />
        ) : users && users.length > 0 ? (
          <>
            <TableSyncDataStyles className="dataCustomTable">
              <Table
                className="mt-3 table-list"
                striped
                borderless
                hover
                responsive
              >
                <thead>
                  <tr className="title-table">
                    <th className="border-radius-left">Status</th>
                    <th>Identificador</th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Criado em</th>
                    <th>Id Integração</th>
                    <th className="border-radius-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item) => {
                    return (
                      <tr
                        id="tr-linha"
                        key={item.id}
                        className="table-values cursor-pointer"
                      >
                        {renderStatusTableData(item.active)}
                        <td>{item.identifier}</td>
                        <td>{item.displayName}</td>
                        <td>{item.email ? item.email : "-"}</td>

                        <td>{formatDateTimeView(item.creationDate)}</td>
                        <td>{item.serviceId}</td>
                        <td className="tableActions">
                          <CustomTooltip
                            type="action-button"
                            text="Detalhes da pessoa"
                            placement="bottom"
                            buttonContent={
                              <div
                                role="button"
                                className="action-item"
                                onClick={() => GoDetails(item)}
                              >
                                <BsInfoCircle color="#0F7EAA" size={24} />
                              </div>
                            }
                          />
                          <CustomTooltip
                            type="action-button"
                            text="Histórico de registros"
                            placement="bottom"
                            buttonContent={
                              <div
                                role="button"
                                className="action-item"
                                onClick={() => GoDetailsHistory(item)}
                              >
                                <BsFileRichtext color="#0F7EAA" size={24} />
                              </div>
                            }
                          />
                          <CustomTooltip
                            type="action-button"
                            text="Acessar turmas da pessoa"
                            placement="bottom"
                            buttonContent={
                              <div
                                role="button"
                                className="action-item"
                                onClick={() => handleViewTeam(item)}
                              >
                                <BsMortarboard color="#0F7EAA" size={24} />
                              </div>
                            }
                          />
                          <CustomTooltip
                            type="action-button"
                            text={
                              item.active
                                ? "Inativar pessoa"
                                : "Reativar pessoa"
                            }
                            placement="bottom"
                            buttonContent={
                              <div
                                role="button"
                                className="action-item"
                                onClick={() => handleActiveOnClick(item)}
                              >
                                <BsXOctagon color="#A56300" size={24} />
                              </div>
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Col md={12}>
                {paging && (
                  <>
                    <BrainzPagination
                      setQueryParams={setQueryPaginationParams}
                      paging={paging}
                    />
                  </>
                )}
              </Col>
            </TableSyncDataStyles>
          </>
        ) : (
          <NoResults clearFilterFunction={() => ClearFilters()}></NoResults>
        )}
      </Row>

      {/* Modal Detalhes da pessoa */}
      {itemDetails !== undefined && (
        <ModalGenericStyles show={show} onHide={handleModalDetailUserClose}>
          <Modal.Header>
            <Modal.Title>
              <h2>Detalhes da Pessoa</h2>
            </Modal.Title>
            <Button
              className="cursor-pointer"
              variant="white"
              onClick={() => handleModalDetailUserClose()}
            >
              <BsX color="var(--bs-modal-color)" size={28} />
            </Button>
          </Modal.Header>

          <Modal.Body>
            {loadingModal === true ? (
              <PageLoader />
            ) : (
              <>
                {buttonEdit === true && (
                  <Row className="info-edit mt-2 ">
                    <Col md={1}>
                      <BsInfoCircle
                        className="bi bi-info-circle"
                        color="#A56300"
                        size={24}
                      />
                    </Col>
                    <Col md={11}>
                      <p>
                        A alteração dos detalhes do registro pode gerar
                        conflitos com outras integrações feitas de forma
                        automática ou via planilha. Você pode conferir o
                        resultado da alteração na tela Logs de Execução.
                      </p>
                    </Col>
                  </Row>
                )}
                <Form>
                  <Form.Group as={Row} className=" my-0 pt-3">
                    <Col sm={5}>
                      <Form.Label column sm={12} className=" my-0 py-0">
                        Status
                      </Form.Label>
                      <Form.Label
                        column
                        sm={12}
                        className=" my-0 py-0 row pl-4"
                      >
                        {itemDetails.active === true ? (
                          <td className="success-status">
                            <BsCheckCircle
                              className="mr-2"
                              color="#008555"
                              size={24}
                            />
                          </td>
                        ) : (
                          <td className="error-status">
                            <BsXOctagon
                              className="mr-2"
                              color="#A56300"
                              size={24}
                            />
                          </td>
                        )}
                        {itemDetails.active === true ? "Ativo" : "Inativo"}
                      </Form.Label>
                    </Col>
                    <Col sm={6}>
                      <Form.Label column className=" my-0 py-0">
                        Criado em
                      </Form.Label>
                      <Form.Label column className=" my-0 py-0">
                        <h2>
                          {formatDateTimeView(
                            itemDetails.creationDate?.toLocaleString()
                          )}
                        </h2>
                      </Form.Label>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className=" my-0 py-0">
                    <Col sm={5}>
                      <Form.Label column className=" my-0 py-0">
                        Identificador
                      </Form.Label>
                      <Form.Label column sm={12} className=" my-0 py-0">
                        <h2>{itemDetails.identifier}</h2>
                      </Form.Label>
                    </Col>
                    <Col sm={6}>
                      <Form.Label column className=" my-0 py-0">
                        Tipo de Pessoa
                      </Form.Label>
                      <Form.Label column sm={12} className=" my-0 py-0">
                        <h2>
                          {itemDetails.type === 4 && "Aluno"}
                          {itemDetails.type === 5 && "Professor"}
                          {itemDetails.type === 1 && "Administrativo"}
                          {itemDetails.type === 3 && "Coordenador"}
                        </h2>
                      </Form.Label>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className=" my-0 py-0">
                    <Col sm={11}>
                      <Form.Label column sm={12} className=" my-0 py-0">
                        E-mail
                      </Form.Label>
                      <Form.Label column sm={12} className=" my-0 py-0">
                        <h2>{email === "" ? itemDetails.email : email}</h2>
                      </Form.Label>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className=" my-0 pb-2">
                    <Col>
                      <Form.Label column className=" my-0 py-0">
                        Nome Apresentação
                      </Form.Label>
                      {buttonEdit === false ? (
                        <Form.Label column sm={12} className=" my-0 py-0">
                          <h2>
                            {displayName === ""
                              ? itemDetails.displayName
                              : displayName}
                          </h2>
                        </Form.Label>
                      ) : (
                        <>
                          <Form.Control
                            placeholder=""
                            type="text"
                            name="displayname"
                            value={displayName}
                            //isInvalid={isInvalidFeedBack("displayName", validationErrors)}
                            onChange={(e) => {
                              handleDisplayNameOnChange(e.target.value);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {feedBack("displayName", validationErrors)}
                          </Form.Control.Feedback>
                        </>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className=" my-0 pb-2">
                    <Col>
                      <Form.Label column className=" my-0 py-0">
                        Matrícula
                      </Form.Label>
                      {buttonEdit === false ? (
                        <Form.Label column sm={12} className=" my-0 py-0">
                          <h2>
                            {registration === ""
                              ? itemDetails.registration
                              : registration}
                          </h2>
                        </Form.Label>
                      ) : (
                        <>
                          <Form.Control
                            placeholder=""
                            type="text"
                            name="registration"
                            value={registration}
                            //isInvalid={isInvalidFeedBack("displayName", validationErrors)}
                            onChange={(e) => {
                              handleRegistrationOnChange(e.target.value);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {feedBack("registration", validationErrors)}
                          </Form.Control.Feedback>
                        </>
                      )}
                    </Col>
                    {userSettings?.integrateDocument ? (
                      <Col>
                        <Form.Label column className=" my-0 py-0">
                          Documento
                        </Form.Label>
                        {buttonEdit === false ? (
                          <Form.Label column sm={12} className=" my-0 py-0">
                            <h2>
                              {document === ""
                                ? itemDetails.document
                                : document}
                            </h2>
                          </Form.Label>
                        ) : (
                          <>
                            <Form.Control
                              placeholder=""
                              type="text"
                              name="document"
                              value={document}
                              //isInvalid={isInvalidFeedBack("displayName", validationErrors)}
                              onChange={(e) => {
                                handleDocumentOnChange(e.target.value);
                              }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {feedBack("document", validationErrors)}
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Col>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Row} className=" my-0 pb-2">
                    {userSettings?.integrateDepartment ? (
                      <Col>
                        <Form.Label column className=" my-0 py-0">
                          Departamento
                        </Form.Label>
                        {buttonEdit === false ? (
                          <Form.Label column sm={12} className=" my-0 py-0">
                            <h2>
                              {department === ""
                                ? itemDetails.department
                                : department}
                            </h2>
                          </Form.Label>
                        ) : (
                          <>
                            <Form.Control
                              placeholder=""
                              type="text"
                              name="department"
                              value={department}
                              //isInvalid={isInvalidFeedBack("displayName", validationErrors)}
                              onChange={(e) => {
                                handleDepartmentOnChange(e.target.value);
                              }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {feedBack("department", validationErrors)}
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Col>
                    ) : null}
                    {userSettings?.integrateJobTitle ? (
                      <Col>
                        <Form.Label column className=" my-0 py-0">
                          Cargo
                        </Form.Label>
                        {buttonEdit === false ? (
                          <Form.Label column sm={12} className=" my-0 py-0">
                            <h2>
                              {jobTitle === ""
                                ? itemDetails.jobTitle
                                : jobTitle}
                            </h2>
                          </Form.Label>
                        ) : (
                          <>
                            <Form.Control
                              placeholder=""
                              type="text"
                              name="jobTitle"
                              value={jobTitle}
                              //isInvalid={isInvalidFeedBack("displayName", validationErrors)}
                              onChange={(e) => {
                                handleJobTitleOnChange(e.target.value);
                              }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {feedBack("jobTitle", validationErrors)}
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Col>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Row} className=" my-0 pb-2">
                    {userSettings?.integrateBirthday ? (
                      <Col>
                        <Form.Label column className=" my-0 py-0">
                          Data de Nascimento
                        </Form.Label>
                        {buttonEdit === false ? (
                          <Form.Label column sm={12} className=" my-0 py-0">
                            <h2>
                              {itemDetails.birthday === null
                                ? ""
                                : formatFromISODate(
                                    itemDetails.birthday?.toLocaleString()
                                  )}
                            </h2>
                          </Form.Label>
                        ) : (
                          <>
                            <Form.Control
                              placeholder=""
                              type="text"
                              name="birthday"
                              value={birthday}
                              //isInvalid={isInvalidFeedBack("displayName", validationErrors)}
                              onChange={(e) => {
                                handleBirthdayOnChange(e.target.value);
                              }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {feedBack("birthday", validationErrors)}
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Col>
                    ) : null}

                    {userSettings?.integrateCellphone ? (
                      <Col>
                        <Form.Label column className=" my-0 py-0">
                          Celular
                        </Form.Label>
                        {buttonEdit === false ? (
                          <Form.Label column sm={12} className=" my-0 py-0">
                            <h2>
                              {cellphone === ""
                                ? itemDetails.cellphone
                                : cellphone}
                            </h2>
                          </Form.Label>
                        ) : (
                          <>
                            <Form.Control
                              placeholder=""
                              type="text"
                              name="cellphone"
                              value={cellphone}
                              //isInvalid={isInvalidFeedBack("displayName", validationErrors)}
                              onChange={(e) => {
                                handleCellphoneOnChange(e.target.value);
                              }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {feedBack("cellphone", validationErrors)}
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Col>
                    ) : null}
                  </Form.Group>
                </Form>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {buttonEdit === false ? (
              <>
                <div></div>
                <div className="">
                  <Button
                    className="continue-button"
                    variant="primary"
                    onClick={() => handleUpdateOnClick(itemDetails)}
                  >
                    <BsPencil className="mr-2" size={20} />
                    Editar Pessoa
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Button
                  className="cancel-operation"
                  variant="primary"
                  onClick={handleCancelUpdate}
                >
                  <BsX color="#A56300" size={24} />
                  Cancelar Edição
                </Button>
                <Button
                  className="continue-button"
                  variant="primary"
                  onClick={handleSaveData}
                >
                  Salvar
                  <BsCheck2 color="#fff" size={24} />
                </Button>
              </>
            )}
          </Modal.Footer>
        </ModalGenericStyles>
      )}

      {/* Modal Histórico de Registros */}
      <ModalGenericStyles
        show={showHistoryModal}
        onHide={handleModalHistoryUser}
      >
        <Modal.Header>
          <Modal.Title>
            <h2>Histórico de registros da pessoa</h2>
          </Modal.Title>
          <Button
            className="cursor-pointer"
            variant="white"
            onClick={() => handleModalHistoryUser()}
          >
            <BsX color="var(--bs-modal-color)" size={28} />
          </Button>
        </Modal.Header>

        <Modal.Body>
          {loadingLogs ? (
            <TailSpin
              color="#2155ba"
              height={50}
              width={50}
              wrapperStyle={{
                margin: "auto",
                placeContent: "center",
              }}
            />
          ) : logsHistory && logsHistory.length > 0 ? (
            <TableSyncDataStyles
              className="dataCustomTable"
              style={{ height: "100%" }}
            >
              <Table
                className="mt-3 table-list"
                striped
                borderless
                hover
                responsive
              >
                <thead>
                  <tr className="title-table">
                    <th className="border-radius-left">Criado em</th>
                    <th className="border-radius-right">Mensagem</th>
                  </tr>
                </thead>
                <tbody>
                  {logsHistory?.map((item: any) => {
                    return (
                      <tr
                        id="tr-linha"
                        key={item.logId}
                        className="table-values cursor-pointer"
                      >
                        <td>
                          {formatDateTimeView(
                            item.creationDate?.toLocaleString()
                          )}
                        </td>
                        <td>{item.message}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {pagingLogs && (
                <PaginationContainer>
                  <BrainzPagination
                    setQueryParams={setQueryPaginationParamsLogs}
                    paging={pagingLogs}
                  />
                </PaginationContainer>
              )}
            </TableSyncDataStyles>
          ) : (
            <Row className="m-3">
              <span>Nenhum histórico de registros.</span>
            </Row>
          )}
        </Modal.Body>
      </ModalGenericStyles>

      <ConfirmModal
        show={showActiveUser && itemActive === true}
        onHide={handleModalActiveUserClose}
        title="Deseja inativar esse usuário?"
        subTitle="Você poderá ativar esse usuário novamente."
        confirmAction={deleteData}
        refuseAction={handleModalActiveUserClose}
        confirmText="Inativar usuário"
        refuseText="Manter ativo"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />
      <ConfirmModal
        show={showActiveUser && itemActive === false}
        onHide={handleModalActiveUserClose}
        title="Deseja reativar esse usuário?"
        subTitle="Você poderá inativar esse usuário novamente."
        confirmAction={reactiveData}
        refuseAction={handleModalActiveUserClose}
        confirmText="Reativar usuário"
        refuseText="Manter inativo"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />
      {activeModalViewTeam && (
        <ModalViewTeamUser
          activeModalViewTeam={activeModalViewTeam}
          setActiveModalViewTeam={setActiveModalViewTeam}
          itemDetails={itemDetails}
        />
      )}
    </>
  );
}
