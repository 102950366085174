import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BsCheckCircle,
  BsTrash,
  BsX,
  BsXCircle,
  BsXOctagon,
} from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzPagination, {
  PaginationProps,
} from "../../../components/Common/BrainzPagination/BrainzPagination";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import Search from "../../../components/Common/Input/Search";
import PageLoader from "../../../components/Common/Loader";
import NoResults from "../../../components/Common/NoResults";
import { showToast } from "../../../components/Common/Toast";
import { handleGetPageSize } from "../../../helpers/functions";
import { BrainzUserTeamPayload } from "../../../interfaces/BrainzUserTeamPayload";
import UserTeamApiInterface from "../../../services/UserTeamApiInterface";
import { TableSyncDataStyles } from "../../../styles/generic";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
interface ModalViewTeamUserProps {
  activeModalViewTeam: boolean;
  setActiveModalViewTeam: React.Dispatch<React.SetStateAction<boolean>>;
  itemDetails: any;
}
export default function ModalViewTeamUser(props: ModalViewTeamUserProps) {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [t] = useTranslation("common");
  const [searchFilterTeam, setSearchFilterTeam] = useState<string>("");
  const [queryPaginationParams, setQueryPaginationParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [buttonClearFilters, setButtonClearFilters] = useState(false);
  const [teamsUser, setTeamsUser] = useState<any>();
  const [loading, setLoading] = useState(true);

  function queryPagination() {
    let result: string;
    if (queryPaginationParams) result = `${queryPaginationParams}`;
    else result = "";
    return result;
  }

  function queryFilters() {
    let query = "";
    if (searchFilterTeam !== "") query = query += "&search=" + searchFilterTeam;
    if (query !== "") setButtonClearFilters(true);
    else setButtonClearFilters(false);
    return query;
  }

  function loadPage() {
    setLoading(true);
    if (queryPaginationParams) {
      UserTeamApiInterface.get(
        `/UserTeam/${institutionId}/${
          props.itemDetails.identifier
        }/ListTeams${queryPagination()}${queryFilters()}`
      )
        .then((response) => {
          setPaging(response.data.paging);
          setTeamsUser(response.data.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  }

  function gotoPageOne() {
    if (paging) {
      const paginationProps: PaginationProps = {
        currentRecord: paging.currentRecord,
        currentPage: 1,
        pageSize: paging.pageSize,
        recordsOnPage: paging.recordsOnPage,
        totalRecords: paging.totalRecords,
        totalPages: paging.totalPages,
      };
      setPaging(paginationProps);
      setQueryPaginationParams(`?page=1&pageSize=${handleGetPageSize()}`);
    }
  }

  function ClearFilters() {
    setSearchFilterTeam("");
    setButtonClearFilters(false);
  }

  function handleDeleteLinkUserTeam(
    userIdentifier: string,
    teamIdentifier: string,
    userType: number
  ) {
    setLoading(true);
    const payload: Array<BrainzUserTeamPayload> = [
      {
        teamIdentifier,
        userIdentifier,
      },
    ];

    setLoading(true);
    return UserTeamApiInterface.delete(
      `/UserTeam/${institutionId}/${userType}/Inactive`,
      { data: payload }
    )
      .then((response) => {
        if (response.status === 200) {
          const errors = response.data.result[0].errors;
          if (!errors || errors.length === 0) {
            setLoading(false);
            showToast("info", "Concluído com sucesso.");
            loadPage();
          } else {
            setLoading(false);
            showToast("error", t("errors." + errors[0].code));
          }
        } else {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro => ", error);
      });
  }

  useEffect(() => {
    loadPage();
  }, [queryPaginationParams, searchFilterTeam, buttonClearFilters]);

  useEffect(() => {
    gotoPageOne();
  }, [searchFilterTeam, buttonClearFilters]);

  return (
    <ModalGenericStyles
      show={props.activeModalViewTeam === true}
      onHide={() => props.setActiveModalViewTeam(false)}
    >
      <Modal.Header>
        <Modal.Title>
          <h2> Turmas da Pessoa</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => props.setActiveModalViewTeam(false)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className=" my-0 pt-3">
            <Col sm={3}>
              <Form.Label column sm={12} className=" my-0 py-0">
                Status
              </Form.Label>
              <Form.Label column sm={12} className=" my-0 py-0 row pl-4">
                {props.itemDetails.active === true ? (
                  <td className="success-status">
                    <BsCheckCircle className="mr-2" color="#008555" size={24} />
                  </td>
                ) : (
                  <td className="error-status">
                    <BsXOctagon className="mr-2" color="#A56300" size={24} />
                  </td>
                )}
                {props.itemDetails.active === true ? "Ativo" : "Inativo"}
              </Form.Label>
            </Col>
            <Col sm={4}>
              <Form.Label column className=" my-0 py-0">
                Identificador da pessoa
              </Form.Label>
              <Form.Label column sm={12} className=" my-0 py-0">
                {props.itemDetails.identifier}
              </Form.Label>
            </Col>
            {/* validar a quantidade de turmas vinculadas */}
            <Col sm={4} style={{ marginLeft: "auto" }}>
              {paging && (
                <Form.Label column className=" mt-4 py-0">
                  {paging.totalRecords} Vínculos de turma
                </Form.Label>
              )}
            </Col>
          </Form.Group>
        </Form>
        <Row>
          <Col md={!buttonClearFilters ? 12 : 10}>
            <Form.Group>
              <div className="search-filter mx-2 my-3">
                <Search
                  setSearchFilter={setSearchFilterTeam}
                  searchFilter={searchFilterTeam}
                  placeholder="Buscar turma por identificador"
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={2} className="mt-3 ">
            {buttonClearFilters && (
              <Button className="clear-filter " onClick={ClearFilters}>
                <BsXCircle
                  className="bi bi-x-circle"
                  color="#A56300"
                  size={24}
                />
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          {loading ? (
            <PageLoader />
          ) : teamsUser && teamsUser.length > 0 ? (
            <TableSyncDataStyles
              className="dataCustomTable"
              style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
            >
              <Table className="table-list" striped borderless hover responsive>
                <thead>
                  <tr
                    className="title-table"
                    style={{ textAlignLast: "start" }}
                  >
                    <th className="border-radius-left">Nome</th>
                    <th>Identificador</th>
                    <th className="border-radius-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {teamsUser?.map((item: any, index: number) => {
                    return (
                      <tr className="table-values" key={index}>
                        <td style={{ textAlignLast: "start" }}>
                          {item.team.name}
                        </td>
                        <td style={{ textAlignLast: "start" }}>
                          {item.team.identifier}
                        </td>
                        <td style={{ textAlignLast: "end" }}>
                          <CustomTooltip
                            type="custom-icon"
                            text="Excluir"
                            placement="bottom"
                            customIcon={
                              <BsTrash
                                onClick={() => {
                                  handleDeleteLinkUserTeam(
                                    item.user.identifier,
                                    item.team.identifier,
                                    item.userType
                                  );
                                }}
                                className="mr-2 cursor-pointer"
                                color="#A56300"
                                size={24}
                              />
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {paging && (
                <BrainzPagination
                  setQueryParams={setQueryPaginationParams}
                  paging={paging}
                  customStyle={{ marginRight: 0 }}
                />
              )}
            </TableSyncDataStyles>
          ) : (
            <NoResults clearFilterFunction={() => ClearFilters()} />
          )}
        </Row>
      </Modal.Body>
    </ModalGenericStyles>
  );
}
