import { useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzPagination, {
  PaginationProps,
} from "../../../../components/Common/BrainzPagination/BrainzPagination";
import PageLoader from "../../../../components/Common/Loader";
import { showToast } from "../../../../components/Common/Toast";
import { formatDateTimeView } from "../../../../helpers/DateTime";
import { handleGetPageSize } from "../../../../helpers/functions";
import ApiInterface from "../../../../services/ApiInterface";
import { TableSyncDataStyles } from "../../../../styles/generic";
import { ModalGenericStyles } from "../../../../styles/modalsGeneric";
import { ActiveModalContext } from "../../SyncDataContexts/ActiveModalContext";
import { ModalLogContainer, PaginationContainer } from "./styles";

export function ModalLogsHistory() {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const { activeModal, setActiveModal, identifierTeam } =
    useContext(ActiveModalContext);

  const [isLoading, setLoading] = useState(false);
  const [pagingLogs, setPagingLogs] = useState<PaginationProps | undefined>();
  const [logsHistory, setLogsHistory] = useState<any[]>([]);
  const [queryPaginationParamsLogs, setQueryPaginationParamsLogs] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );

  function queryPaginationLogs() {
    let result: string = "";
    if (queryPaginationParamsLogs) {
      const splitted = queryPaginationParamsLogs?.split("&");
      if (splitted) {
        result = `${splitted[0]}&pageSize=${handleGetPageSize()}`;
      }
    }
    return result;
  }

  const handleLoadLogsHistory = (identifier: string) => {
    if (queryPaginationParamsLogs) {
      setLoading(true);
      return ApiInterface.get(
        `/Log/GetLogByIdentifier/Identifier/${institutionId}/${identifier}/2${queryPaginationLogs()}`
      )
        .then((response) => {
          setPagingLogs(response.data.paging);
          setLogsHistory(response.data.result.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error(
            "Erro ao tentar pegar logs de registros de turma. Erro => ",
            error
          );
        });
    }
  };

  const handleClearStates = () => {
    setQueryPaginationParamsLogs(`?page=1&pageSize=${handleGetPageSize()}`);
    setActiveModal(0);
  };

  useEffect(() => {
    if (identifierTeam && identifierTeam !== "") {
      handleLoadLogsHistory(identifierTeam);
    }
  }, [queryPaginationParamsLogs, identifierTeam]);

  return (
    <ModalGenericStyles
      show={activeModal === 22}
      onHide={handleClearStates}
      className="SyncModalRegister"
    >
      <Modal.Header>
        <Modal.Title className="ml-3">
          <h2>Histórico de Registros</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleClearStates}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <PageLoader />
        ) : logsHistory && logsHistory.length !== 0 ? (
          <ModalLogContainer>
            <TableSyncDataStyles
              className="dataCustomTable"
              style={{ height: "100%" }}
            >
              <Table
                className="mt-3 table-list"
                striped
                borderless
                hover
                responsive
              >
                <thead>
                  <tr className="title-table">
                    <th className="border-radius-left">Criado em</th>
                    <th className="border-radius-right">Mensagem</th>
                  </tr>
                </thead>
                <tbody>
                  {logsHistory?.map((item: any) => {
                    return (
                      <tr
                        id="tr-linha"
                        key={item.logId}
                        className="table-values cursor-pointer"
                      >
                        <td>
                          {formatDateTimeView(
                            item.creationDate?.toLocaleString()
                          )}
                        </td>
                        <td>{item.message}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {pagingLogs && (
                <PaginationContainer>
                  <BrainzPagination
                    setQueryParams={setQueryPaginationParamsLogs}
                    paging={pagingLogs}
                  />
                </PaginationContainer>
              )}
            </TableSyncDataStyles>
          </ModalLogContainer>
        ) : (
          <div className="ml-3">
            <span>Não existe histórico para esta turma.</span>
          </div>
        )}
      </Modal.Body>
    </ModalGenericStyles>
  );
}
