import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  BsArrowLeft,
  BsArrowRight,
  BsCheck2,
  BsChevronRight,
  BsX,
} from "react-icons/bs";
import Cookies from "universal-cookie";

import CustomTooltip from "../../../components/Common/CustomTooltip";
import PageLoader from "../../../components/Common/Loader";
import Stepper from "../../../components/Common/Stepper";
import { showToast } from "../../../components/Common/Toast";
import Toggle from "../../../components/Common/Toggle";
import ApiInterface from "../../../services/ApiInterface";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { GeneralConfigurationContext } from "../GeneralConfigurationContexts/GeneralConfigurationContext";
import {
  customTooltipTextEmailsOption1,
  customTooltipTextAutomaticPassword,
} from "./utils/functions";
import "./styles/cardStyles.css";

export interface ModelDomain {
  domain: string;
  active: boolean;
}

interface FirstConfigurationProps {
  loadPageProfile: () => void;
}

export default function ModalFirstConfigurationProfile(
  props: FirstConfigurationProps
) {
  const {
    activeModal,
    setActiveModal,
    entityType,
    userType,
    setFirstUpdateIntegration,
  } = useContext(GeneralConfigurationContext);

  // const { peddingConfiguration } = useContext(ProfileContext);
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [loading, setLoading] = useState(false);
  const [stepFirstConfig, setStepFirstConfig] = useState(1);
  const [emailType, setEmailType] = useState(1);
  const [passwordType, setPasswordType] = useState(3);
  const [passwordFixed, setPasswordFixed] = useState("");
  const [activeChangePassword, setActiveChangePassword] = useState(true);
  const [jobTitle, setJobTitle] = useState(true);
  const [document, setDocument] = useState(true);
  const [cellPhone, setCellPhone] = useState(true);
  const [department, setDepartment] = useState(true);
  const [birthday, setBirthday] = useState(true);
  const [domains, setDomains] = useState(["your@domain.com"]);
  const [autoLink, setAutoLink] = useState<boolean>(false);
  const [domainSelected, setDomainSelected] = useState<any>([
    "your@domain.com",
  ]);
  const [licenseSelected, setLicenseSelected] = useState("");
  const [licenses, setLicenses] = useState([
    {
      id: "0",
      name: "Gratuita",
      licenseKey: "0",
    },
  ]);

  const [domainList, setDomainList] = useState<Array<ModelDomain>>([
    {
      domain: "your@domain.com",
      active: false,
    },
  ]);

  function ClearModal() {
    setStepFirstConfig(1);
    setLicenseSelected("");
  }

  function saveData() {
    setLoading(true);

    const payload = {
      institutionId,
      userType,
      defaultEmail: emailType,
      licenseKey: licenseSelected,
      emailDomain:
        emailType === 1 || emailType === 5
          ? domainList
              .map((item: any) => {
                if (item.active === true) {
                  return item.domain;
                }
              })
              .toString()
          : domainSelected,
      defaultPassword: passwordType, // 1 : Fixed, 2 : Automatic, 3 : Ready, 4 : Random
      passwordCreationPattern: 1, // 1 : NameAtYearAtRegistration, 2 : BirthdayAtNameAtRegistration
      requirePasswordRedefinition: activeChangePassword,
      fixedPassword: passwordFixed,
      integrateBirthday: birthday,
      integrateCellphone: cellPhone,
      integrateDepartment: department,
      integrateJobTitle: jobTitle,
      integrateDocument: document,
      autoLink,
    };

    ApiInterface.post(
      "/UserIntegrationSettings/" + institutionId + "/Insert",
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          showToast("success", "Salvo com sucesso");
          props.loadPageProfile();
          ClearModal();
          setActiveModal(0);
          setLoading(false);
          setFirstUpdateIntegration(true);
          window.location.reload();
        } else {
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          setLoading(false);
        }
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        setLoading(false);
        console.error("Erro => ", error);
      });
  }

  function setHandleDomainsList(index: number) {
    const domainsListOld = [...domainList];
    const item = domainsListOld[index];
    item.active = !item.active;
    domainsListOld[index] = item;
    setDomainList(domainsListOld);
  }

  useEffect(() => {
    function loadPage() {
      setLoading(true);
      ApiInterface.get("/Institution/GetDomains/" + institutionId)
        .then((response) => {
          setLoading(false);
          setDomains(response.data.result);
          const domaisLocalList = response.data.result.map((item: any) => {
            return {
              domain: item,
              active: false,
            };
          });
          setDomainList(domaisLocalList);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Erro => ", error);
        });

      setLoading(true);
      ApiInterface.get("/Institution/GetLicenses/" + institutionId)
        .then((response) => {
          setLoading(false);
          setLicenses(response.data.result);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Erro => ", error);
        });
    }
    loadPage();
  }, [activeModal]);

  if (loading) return <PageLoader />;

  return (
    <ModalGenericStyles
      show={activeModal === 1}
      onHide={() => setActiveModal(0)}
      className="ModalDomains ModalFirstConfigurationProfile"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            {entityType}
            <BsChevronRight size={18} />
            {stepFirstConfig === 1 && "  Padrão de criação dos e-mails"}
            {stepFirstConfig === 2 && "  Padrão de criação dos e-mails"}
            {stepFirstConfig === 3 && "  Senha padrão dos e-mails"}
            {stepFirstConfig === 4 && "  Redefinição de Senha"}
            {stepFirstConfig === 5 && "  Dados a serem exibidos (opcional)"}
            {stepFirstConfig === 6 && "  Licença Atribuída"}
            {stepFirstConfig === 7 && "  Domínio"}
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={24} />
        </Button>
      </Modal.Header>
      {stepFirstConfig === 1 && (
        <Modal.Body>
          <Row>
            <Col>
              <h4>Selecione como serão gerados os endereços de e-mail.</h4>
            </Col>
          </Row>

          <Row>
            <Col
              className={
                emailType === 1 ? "card-option-selected" : "card-option"
              }
            >
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Seguir planilha/consulta"
                checked={emailType === 1}
                onChange={() => setEmailType(1)}
              />
              <h3>
                As contas serão criadas com os endereços de e-mail especificados
                na integração.
              </h3>
            </Col>
          </Row>

          <Row>
            <Col
              className={
                emailType === 2 ? "card-option-selected" : "card-option"
              }
            >
              <div className="card-with-tooltip">
                <Form.Check
                  className="button-radio mb-3 mt-2"
                  type="radio"
                  label="Geração Automática - Opção 1"
                  checked={emailType === 2}
                  onChange={() => setEmailType(2)}
                />

                <CustomTooltip
                  type="info"
                  iconColor="#6F6F6F"
                  iconSize={20}
                  text={customTooltipTextEmailsOption1}
                  placement="right"
                  isHTMLText
                />
              </div>

              <h3>
                Os e-mails serão gerados automaticamente com o seguinte formato:{" "}
                <strong>PN.UN@dominio</strong>. Caso não seja possível criar um
                e-mail nesse formato, serão utilizadas várias outras combinações
                com o nome e sobrenome.
              </h3>
            </Col>
          </Row>

          <Row>
            <Col
              className={
                emailType === 4 ? "card-option-selected" : "card-option"
              }
            >
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Geração Automática - Opção 2"
                checked={emailType === 4}
                onChange={() => setEmailType(4)}
              />
              <h3>
                Os e-mails serão gerados de forma automática. O formato a ser
                criado será <strong>PN.UN.DATA@dominio</strong>, caso nessa
                possível criar com esse formato, a segunda forma de validação
                será <strong>PN.UN.Matricula@dominio</strong>.
              </h3>
            </Col>
          </Row>

          <Row>
            <Col
              className={
                emailType === 6 ? "card-option-selected" : "card-option"
              }
            >
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Geração Automática - Opção 3"
                checked={emailType === 6}
                onChange={() => setEmailType(6)}
              />
              <h3>
                Os e-mails serão gerados automaticamente com o seguinte formato:{" "}
                <strong>MT@dominio</strong>.
              </h3>
            </Col>
          </Row>

          <Row>
            <Col
              className={
                emailType === 7 ? "card-option-selected" : "card-option"
              }
            >
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Geração Automática - Opção 4"
                checked={emailType === 7}
                onChange={() => setEmailType(7)}
              />
              <h3>
                Os e-mails serão gerados automaticamente com o seguinte formato:{" "}
                <strong>PN.MT@dominio</strong>.
              </h3>
            </Col>
          </Row>

          <Row>
            <Col
              className={
                emailType === 5 ? "card-option-selected" : "card-option"
              }
            >
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Usuários Federados - Opção 5"
                checked={emailType === 5}
                onChange={() => setEmailType(5)}
              />
              <h3>
                Não haverá geração de e-mails, apenas serão vinculados os
                e-mails já existentes.
              </h3>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <h4>
                <strong>LEGENDA:</strong>
                <br />
                PN: Primeiro Nome
                <br />
                UN: Último Nome
                <br />
                Data: Data de Nascimento <br />
                MT: Matrícula
              </h4>
            </Col>
          </Row>
        </Modal.Body>
      )}

      {stepFirstConfig === 2 && (
        <Modal.Body>
          <Row className="mx-auto">
            <Col md={8}>
              <Row className="mb-2">
                <h4>
                  <strong>
                    Vincular novos usuários com contas já existentes
                  </strong>
                </h4>
              </Row>
              <Row>
                <span>
                  Ao tentar criar um novo usuário, verifica no provider a
                  existência da conta criada. Caso já exista, realiza o vínculo.
                </span>
              </Row>
            </Col>
            <Col md={4} className="d-flex justify-content-end">
              <div className="mt-3 cursor-pointer">
                <Toggle
                  handleSomething={() => {
                    setAutoLink(!autoLink);
                  }}
                  isSomething={autoLink}
                  title={autoLink ? "Ativado" : "Desativado"}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
      )}

      {stepFirstConfig === 3 && (
        <Modal.Body>
          <Row>
            <Col>
              <h4>
                Selecione como serão criadas as senhas para acesso às contas.
              </h4>
            </Col>
          </Row>
          <Row>
            <Col
              className={
                passwordType === 3 ? "card-option-selected" : "card-option"
              }
            >
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Seguir planilha/consulta"
                checked={passwordType === 3}
                onChange={() => setPasswordType(3)}
              />
              <h3>
                Serão utilizadas as senhas especificadas na integração. As
                senhas devem respeitar as regras:<br></br>
                <ul>
                  <li>
                    Não pode conter o nome de usuário completo ou endereço da
                    conta de email
                  </li>
                  <li>Não pode conter símbolos de moedas. Exemplo: $, €, £</li>
                </ul>
              </h3>
              <h3 style={{ color: "#c1944f" }}>
                Caso a senha presente na consulta não respeite as regras, o
                usuário não será criado.
              </h3>
            </Col>
          </Row>
          <Row>
            <Col
              className={
                passwordType === 1 ? "card-option-selected" : "card-option"
              }
            >
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Senha fixa"
                checked={passwordType === 1}
                onChange={() => setPasswordType(1)}
              />
              <h3>Defina uma senha única e padrão para todas as contas.</h3>
              {passwordType === 1 ? (
                <Form.Control
                  size="sm"
                  defaultValue={passwordFixed}
                  placeholder="Digite aqui a senha padrão para todas as contas"
                  //isInvalid={isInvalidFeedBack("urlEndpoint", validationErrors)}
                  onChange={(e) => setPasswordFixed(e.target.value)}
                ></Form.Control>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col
              className={
                passwordType === 2 ? "card-option-selected" : "card-option"
              }
            >
              <div className="card-with-tooltip">
                <Form.Check
                  className="button-radio mb-3 mt-2"
                  type="radio"
                  label="Geração automática"
                  checked={passwordType === 2}
                  onChange={() => setPasswordType(2)}
                />
                <CustomTooltip
                  type="info"
                  iconColor="#6F6F6F"
                  iconSize={20}
                  text={customTooltipTextAutomaticPassword}
                  placement="right"
                  isHTMLText
                />
              </div>

              <h3>As senhas serão geradas automaticamente pelo sistema.</h3>
            </Col>
          </Row>
          <Row>
            <Col
              className={
                passwordType === 4 ? "card-option-selected" : "card-option"
              }
            >
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Senha Aleatória"
                checked={passwordType === 4}
                onChange={() => setPasswordType(4)}
              />
              <h3>
                O sistema gerará uma senha aleatória, única para cada conta.
              </h3>
            </Col>
          </Row>
        </Modal.Body>
      )}
      {stepFirstConfig === 4 && (
        <Modal.Body>
          <Row className="mx-auto">
            <Col md={8}>
              <Row className="mb-2">
                <h4>
                  <strong>
                    Solicitar redefinição de senha no primeiro acesso
                  </strong>
                </h4>
              </Row>
              <Row>
                <span>
                  As pessoas terão de alterar a sua senha na primeira vez em que
                  utilizarem a conta.
                </span>
              </Row>
            </Col>
            <Col md={4} className="d-flex justify-content-end">
              <div className="mt-3 cursor-pointer">
                {activeChangePassword === false ? (
                  <Toggle
                    isSomething={activeChangePassword}
                    handleSomething={() => setActiveChangePassword(true)}
                    title="Desativado"
                  />
                ) : (
                  <Toggle
                    isSomething={activeChangePassword}
                    handleSomething={() => setActiveChangePassword(false)}
                    title="Ativado"
                  />
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      )}
      {stepFirstConfig === 5 && (
        <Modal.Body>
          <Row>
            <Col>
              <h4>
                Os seguintes dados das contas serão exibidos nos aplicativos.
                Estes dados deverão estar especificados na integração para serem
                exibidos.
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                className="button-radio my-3"
                checked={jobTitle}
                label="Cargo"
                onChange={() => setJobTitle(!jobTitle)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                className="button-radio my-3"
                checked={document}
                label="Documento"
                onChange={() => setDocument(!document)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                className="button-radio my-3"
                checked={cellPhone}
                label="Celular"
                onChange={() => setCellPhone(!cellPhone)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                className="button-radio my-3"
                checked={department}
                label="Departamento"
                onChange={() => setDepartment(!department)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                className="button-radio my-3"
                checked={birthday}
                label="Data de Nascimento"
                onChange={() => setBirthday(!birthday)}
              />
            </Col>
          </Row>
        </Modal.Body>
      )}
      {stepFirstConfig === 6 && (
        <Modal.Body>
          <Row>
            <Col>
              <h4>
                {" "}
                A seguinte licença será atribuída às contas criadas para este
                perfil.
              </h4>
            </Col>
          </Row>
          {licenses?.map((item) => {
            return (
              <Row key={item.name}>
                <Col
                  className={
                    item.licenseKey === licenseSelected
                      ? "card-option-selected"
                      : "card-option"
                  }
                >
                  <Form.Check
                    className="button-radio mb-3 mt-2"
                    type="radio"
                    label={item.name}
                    checked={item.licenseKey === licenseSelected}
                    onChange={() => setLicenseSelected(item.licenseKey)}
                  />
                </Col>
              </Row>
            );
          })}
        </Modal.Body>
      )}
      {stepFirstConfig === 7 && (
        <Modal.Body>
          <Row>
            <Col>
              <h4>
                Este será o domínio utilizado para a criação de contas com este
                perfil.
              </h4>
            </Col>
          </Row>
          {/* "Pronto na Consulta/Planilha" e Usuários Federados então pode add vários domínios */}
          {emailType === 1 || emailType === 5
            ? domainList?.map((item, index) => {
                return (
                  <Row key={index}>
                    <Col className="">
                      <Form.Check
                        className="button-radio my-3"
                        checked={item.active === true}
                        label={item.domain}
                        onChange={() => setHandleDomainsList(index)}
                      />
                    </Col>
                  </Row>
                );
              })
            : //"Geração Automática" ou "Geração Automática SED" então pode apenas 1 domínio
              domains?.map((item, index) => {
                return (
                  <Row key={index}>
                    <Col
                      className={
                        item === domainSelected
                          ? "card-option-selected"
                          : "card-option"
                      }
                    >
                      <Form.Check
                        className="button-radio mb-3 mt-2"
                        type="radio"
                        label={item}
                        checked={item === domainSelected}
                        onChange={() => setDomainSelected(item)}
                      />
                    </Col>
                  </Row>
                );
              })}
        </Modal.Body>
      )}

      {stepFirstConfig === 1 && (
        <Modal.Footer>
          <Col md={2} />
          <Stepper currentStep={1} totalSteps={7} />
          <Button
            variant="primary"
            className="continue-button"
            //disabled={buttonDisable}
            onClick={() => setStepFirstConfig(2)}
          >
            Próximo
            <BsArrowRight className="ml-2" color="#fff" size={24} />
          </Button>
        </Modal.Footer>
      )}

      {stepFirstConfig === 2 && (
        <Modal.Footer>
          <Button
            variant="primary"
            className="cancel-operation ml-4"
            onClick={() => setStepFirstConfig(1)}
          >
            <BsArrowLeft className="mr-2" color="#A56300" size={24} />
            Voltar
          </Button>
          <Stepper currentStep={2} totalSteps={7} />
          <Button
            variant="primary"
            className="continue-button"
            //disabled={passwordType === 2 && passwordFixed !== ''}
            onClick={() => setStepFirstConfig(3)}
          >
            Próximo
            <BsArrowRight className="ml-2" color="#fff" size={24} />
          </Button>
        </Modal.Footer>
      )}

      {stepFirstConfig === 3 && (
        <Modal.Footer>
          <Button
            variant="primary"
            className="cancel-operation ml-4"
            onClick={() => setStepFirstConfig(2)}
          >
            <BsArrowLeft className="mr-2" color="#A56300" size={24} />
            Voltar
          </Button>
          <Stepper currentStep={3} totalSteps={7} />
          <Button
            variant="primary"
            className="continue-button"
            //disabled={passwordType === 2 && passwordFixed !== ''}
            onClick={() => setStepFirstConfig(4)}
          >
            Próximo
            <BsArrowRight className="ml-2" color="#fff" size={24} />
          </Button>
        </Modal.Footer>
      )}

      {stepFirstConfig === 4 && (
        <Modal.Footer>
          <Button
            variant="primary"
            className="cancel-operation ml-4"
            onClick={() => setStepFirstConfig(3)}
          >
            <BsArrowLeft className="mr-2" color="#A56300" size={24} />
            Voltar
          </Button>
          <Stepper currentStep={4} totalSteps={7} />
          <Button
            variant="primary"
            className="continue-button"
            //disabled={buttonDisable}
            onClick={() => setStepFirstConfig(5)}
          >
            Próximo
            <BsArrowRight className="ml-2" color="#fff" size={24} />
          </Button>
        </Modal.Footer>
      )}
      {stepFirstConfig === 5 && (
        <Modal.Footer>
          <Button
            variant="primary"
            className="cancel-operation ml-4"
            onClick={() => setStepFirstConfig(4)}
          >
            <BsArrowLeft className="mr-2" color="#A56300" size={24} />
            Voltar
          </Button>
          <Stepper currentStep={5} totalSteps={7} />
          <Button
            variant="primary"
            className="continue-button"
            //disabled={buttonDisable}
            onClick={() => setStepFirstConfig(6)}
          >
            Próximo
            <BsArrowRight className="ml-2" color="#fff" size={24} />
          </Button>
        </Modal.Footer>
      )}
      {stepFirstConfig === 6 && (
        <Modal.Footer>
          <Button
            variant="primary"
            className="cancel-operation ml-4"
            onClick={() => setStepFirstConfig(5)}
          >
            <BsArrowLeft className="mr-2" color="#A56300" size={24} />
            Voltar
          </Button>
          <Stepper currentStep={6} totalSteps={7} />
          <Button
            variant="primary"
            className="continue-button"
            //disabled={buttonDisable}
            onClick={() => setStepFirstConfig(7)}
          >
            Próximo
            <BsArrowRight className="ml-2" color="#fff" size={24} />
          </Button>
        </Modal.Footer>
      )}
      {stepFirstConfig === 7 && (
        <Modal.Footer>
          <Button
            variant="primary"
            className="cancel-operation ml-4"
            onClick={() => setStepFirstConfig(6)}
          >
            <BsArrowLeft className="mr-2" color="#A56300" size={24} />
            Voltar
          </Button>
          <Stepper currentStep={7} totalSteps={7} />
          <Button
            variant="primary"
            className="sucess-button"
            //disabled={buttonDisable}
            onClick={() => saveData()}
          >
            Finalizar
            <BsCheck2 className="ml-2" color="#fff" size={24} />
          </Button>
        </Modal.Footer>
      )}
    </ModalGenericStyles>
  );
}
