import pt from "date-fns/locale/pt";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Nav, Row, Table } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  BsArrowRepeat,
  BsExclamationCircle,
  BsInfoCircle,
  BsXCircle,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "universal-cookie";

import BrainzComboBox from "../../components/Common/BrainzComboBox";
import BrainzPagination, {
  PaginationProps,
} from "../../components/Common/BrainzPagination/BrainzPagination";
import CustomTooltip from "../../components/Common/CustomTooltip";
import PageLoader from "../../components/Common/Loader";
import InformationModal from "../../components/Common/Modals/InformationModal";
import NoResults from "../../components/Common/NoResults";
import { showToast } from "../../components/Common/Toast";
import { formatDateTimeView } from "../../helpers/DateTime";
import { handleGetPageSize } from "../../helpers/functions";
import ApiInterface from "../../services/ApiInterface";
import { TableSyncDataStyles } from "../../styles/generic";
import { InitialHeaderStyles } from "../../styles/initialStyles";
import {
  IntegrationLogTableViewModel,
  IntegrationLogViewModel,
  LogsDetailsProps,
} from "./interfaces";
import { FilterStyles, ItemLogsNavBarFilters } from "./styles";
import "../../styles/tableCustomStyles.css";

registerLocale("pt", pt);

function Logs(props: LogsDetailsProps) {
  const navigate = useNavigate();
  const [queryPaginationParams, setQueryPaginationParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [t] = useTranslation("common");
  const [logs, setLogs] = useState<
    Array<IntegrationLogViewModel> | undefined
  >();

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [errorFilter, setErrorsFilter] = useState(false);
  const [integrationTypeFilter, setIntegrationTypeFilter] = useState("");
  const [entityTypeFilter, setEntityTypeFilter] = useState("");
  const [buttonClearFilter, setButtonClearFilter] = useState(false);
  const [showModalInformationError, setShowModalInformationError] =
    useState(false);

  // console.log("filters => ", startDate, endDate, integrationTypeFilter, entityTypeFilter, errorFilter)

  const handleModalInformationErrorClose = () =>
    setShowModalInformationError(false);
  const handleModalInformationErrorShow = () =>
    setShowModalInformationError(true);
  const [errorInformationCode, setErrorInformationCode] = useState("");

  function queryPagination() {
    let result: string;
    if (queryPaginationParams) result = `${queryPaginationParams}`;
    else result = "";
    return result;
  }

  function queryFilters() {
    let query = "";
    if (errorFilter) query = query += "errorFilter=true&";
    if (startDate !== undefined)
      query = query +=
        "startDateFilter=" + startDate.toLocaleDateString() + "&";

    if (endDate !== undefined)
      query = query += "endDateFilter=" + endDate.toLocaleDateString() + "&";
    if (integrationTypeFilter !== "")
      query = query += "integrationTypeFilter=" + integrationTypeFilter + "&";
    if (entityTypeFilter !== "")
      query = query += "entityTypeFilter=" + entityTypeFilter + "&";
    setButtonClearFilter(query !== "");
    return query;
  }

  function loadPage() {
    setLoading(true);
    if (queryPaginationParams)
      ApiInterface.get(
        `/Log/${institutionId}/List${queryPagination()}&${queryFilters()}`
      )
        .then((response) => {
          setPaging(response.data.paging);
          const logList: Array<IntegrationLogViewModel> = response.data.result;
          const newLogList: Array<IntegrationLogTableViewModel> = logList.map(
            (item) => {
              return {
                startDate: item.startDate,
                finishDate: item.finishDate === null ? "-" : item.finishDate,
                updated: item.updated,
                integrationTypeName: item.integrationTypeName,
                integrationTypeEntityName: item.integrationTypeEntityName,
                processing: item.processing,
                errors: item.errors,
                inactivated: item.inactivated,
                reactivated: item.reactivated,
                created: item.created,
                id: item.id,
                integrationLogId: item.integrationLogId,
                logEntityType: item.logEntityType,
                integrationTypeEntityType: item.integrationTypeEntityType,
                errorCode: item.errorCode,
                state: item.state,
                total: item.total,
              };
            }
          );
          setLogs(newLogList);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
  }

  function gotoPageOne() {
    if (paging) {
      const paginationProps: PaginationProps = {
        currentRecord: paging.currentRecord,
        currentPage: 1,
        pageSize: paging.pageSize,
        recordsOnPage: paging.recordsOnPage,
        totalRecords: paging.totalRecords,
        totalPages: paging.totalPages,
      };
      setPaging(paginationProps);
      setQueryPaginationParams(`?page=1&pageSize=${handleGetPageSize()}`);
    }
  }

  function ClearFilters() {
    setStartDate(undefined);
    setEndDate(undefined);
    setErrorsFilter(false);
    setIntegrationTypeFilter("");
    setEntityTypeFilter("");
  }

  const GoDetails = (
    integrationLogId: string,
    logEntityType: number,
    startDate: string,
    integrationTypeName: string,
    integrationTypeEntityName: string,
    integrationTypeEntityType: number
  ) => {
    navigate(`/logs-details?id=${institutionId}`, {
      state: {
        integrationLogId,
        logEntityType,
        startDate,
        integrationTypeName,
        integrationTypeEntityName,
        integrationTypeEntityType,
      },
    });
  };

  function OpenInformationError(errorCode: string) {
    handleModalInformationErrorShow();
    setErrorInformationCode(errorCode);
  }

  function renderFinishDateColumn(item: any): any {
    return (
      <td>
        {formatDateTimeView(item.finishDate)}
        {item.errorCode !== "" && (
          <BsInfoCircle
            className="cursor-pointer ml-2"
            color="#D53B26"
            size={18}
            onClick={() => OpenInformationError(item.errorCode)}
          />
        )}
      </td>
    );
  }

  function renderStatus(item: any): any {
    return <td>{parseStateText(item.state)}</td>;
  }

  function parseStateText(state: number) {
    switch (state) {
      case 1:
        return "Buscando dados";
      case 2:
        return "Tratando dados";
      case 3:
        return "Integrando dados";
      case 4:
        return "Integração concluída";
      default:
        return state;
    }
  }

  function renderLineErrors(item: any): any {
    return (
      <td className={item.errors > 0 ? "bg-error-color" : ""}>{item.errors}</td>
    );
  }

  useEffect(() => {
    loadPage();
  }, [
    queryPaginationParams,
    errorFilter,
    startDate,
    endDate,
    entityTypeFilter,
    integrationTypeFilter,
  ]);

  useEffect(() => {
    gotoPageOne();
  }, [
    errorFilter,
    startDate,
    endDate,
    entityTypeFilter,
    integrationTypeFilter,
  ]);

  return (
    <div>
      <section className="headingStickySubPagesLogs">
        <InitialHeaderStyles>
          <Row>
            <Col md={12} style={{ padding: 0 }}>
              <h2>Logs de Execução</h2>
            </Col>
            <br />
            <br />
            <Col md={12} style={{ padding: 0 }}>
              <h3>
                Revise o que ocorreu em cada integração, tanto automática quanto
                manual. Identifique possíveis erros e sugestões para
                solucioná-los.
              </h3>
            </Col>
          </Row>

          <Row className="mt-3" style={{ padding: 0 }}>
            <Col style={{ padding: 0 }}>
              <h3>
                <strong>Filtrar por período</strong>
              </h3>
            </Col>
          </Row>
        </InitialHeaderStyles>

        <FilterStyles className="ml-0 py-0 my-0 search-filter logs-filter">
          <Col md="1" className="mx-0 ">
            <DatePicker
              className="input-date w-100"
              dateFormat="dd/MM/yyyy"
              selectsRange={false}
              locale="pt"
              placeholderText="Do dia"
              selected={startDate}
              onChange={(date) => date && setStartDate(date as Date)}
              todayButton="Hoje"
            />
          </Col>
          <Col md={1} className="mx-0 pl-0">
            <DatePicker
              className="input-date w-100"
              dateFormat="dd/MM/yyyy"
              selectsRange={false}
              minDate={startDate}
              locale="pt"
              placeholderText={"Até o dia"}
              selected={endDate}
              onChange={(date) => date && setEndDate(date as Date)}
              todayButton="Hoje"
            />
          </Col>
          <Col md={2} className="mx-0 px-0">
            <BrainzComboBox
              setValue={(val) => setIntegrationTypeFilter(val)}
              value={integrationTypeFilter}
              idField="id"
              valueField="name"
              placeholder="Tipo de Integração"
              options={[
                { name: "Automática via Banco", value: "1" },
                { name: "Automática via Api", value: "4" },
                { name: "Planilha", value: "2" },
                { name: "Via Tela", value: "3" },
                { name: "Manual via Banco", value: "5" },
                { name: "Manual via Api", value: "6" },
              ]}
              search={false}
            />
          </Col>
          <Col md={2} className="mx-0 ">
            <BrainzComboBox
              setValue={(val) => setEntityTypeFilter(val)}
              value={entityTypeFilter}
              idField="id"
              valueField="name"
              placeholder="Entidade"
              options={[
                { name: "Administrativo", value: "1" },
                { name: "Alunos", value: "9" },
                { name: "Unidades", value: "8" },
                { name: "Professores", value: "10" },
                { name: "Coordenadores", value: "4" },
                { name: "Turmas", value: "13" },
                { name: "Turmas-Aluno", value: "14" },
                { name: "Turmas-Professor", value: "15" },
                { name: "Turmas-Administrativo", value: "17" },
                { name: "Turmas-Coordenadores", value: "12" },
                // { name: "Agenda", value: "20" },
                { name: "Licenciamento", value: "19" },
              ]}
              search={false}
            />
          </Col>

          <Col md={2} className="mx-0 px-0">
            <ItemLogsNavBarFilters className="p-0 m-0 mx-0 px-0 card">
              <Nav
                variant="pills"
                defaultActiveKey="/home"
                className="px-0 mt-0 mb-0 pl-0 ml-0 "
                style={{ background: "" }}
              >
                <Nav.Item className="m-0 p-0 pl-1">
                  <Nav.Link
                    active={errorFilter === false}
                    onClick={() => setErrorsFilter(false)}
                    style={{ width: 64 + "px" }}
                    className=" card"
                  >
                    Todos
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-0 p-0 pl-1">
                  <Nav.Link
                    active={errorFilter === true}
                    onClick={() => setErrorsFilter(true)}
                    style={{ width: 96 + "px" }}
                    className=" card m-0"
                  >
                    Com Erro
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </ItemLogsNavBarFilters>
          </Col>

          <Col className="filter-div" style={{ textAlign: "right" }}>
            <Row>
              <Col className="mx-0 pr-4">
                {buttonClearFilter && (
                  <CustomTooltip
                    type="action-button"
                    text="Limpar filtro"
                    placement="bottom"
                    buttonContent={
                      <Button
                        className="clear-filter mx-1"
                        onClick={ClearFilters}
                        title="Limpar filtro"
                      >
                        <BsXCircle
                          className="bi bi-x-circle mr-2 "
                          color="#A56300"
                          size={24}
                        />
                      </Button>
                    }
                  />
                )}

                <CustomTooltip
                  type="action-button"
                  text="Atualizar"
                  placement="bottom"
                  buttonContent={
                    <Button className="button-update" onClick={loadPage}>
                      <BsArrowRepeat className="bi bi-x-circle" size={24} />
                    </Button>
                  }
                />
              </Col>
            </Row>
          </Col>
        </FilterStyles>
      </section>

      <Row className="ml-0 mr-2">
        {loading === false ? (
          logs && logs.length > 0 ? (
            <>
              <TableSyncDataStyles className="dataCustomTable">
                <Table
                  className="mt-3 table-list"
                  striped
                  borderless
                  hover
                  responsive
                >
                  <thead>
                    <tr className="title-table">
                      <th className="border-radius-left">Status</th>
                      <th>Data de início</th>
                      <th>Data de término</th>
                      <th>Tipo de integração</th>
                      <th>Entidade</th>
                      <th>Total</th>
                      <th>Com erro</th>
                      <th>Criados</th>
                      <th>Inativados</th>
                      <th>Reativados</th>
                      <th>Atualizados</th>
                      <th className="border-radius-right">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs?.map((item) => {
                      return (
                        <tr
                          id="tr-linha"
                          key={item.id}
                          className="table-values cursor-pointer"
                        >
                          {renderStatus(item)}
                          <td>{formatDateTimeView(item.startDate)}</td>
                          {renderFinishDateColumn(item)}
                          <td>{item.integrationTypeName}</td>
                          <td>{item.integrationTypeEntityName}</td>
                          <td>{item.total}</td>
                          {renderLineErrors(item)}
                          <td>{item.created}</td>
                          <td>{item.inactivated}</td>
                          <td>{item.reactivated}</td>
                          <td>{item.updated}</td>
                          <td>
                            <CustomTooltip
                              type="action-button"
                              text="Acessar detalhes"
                              placement="bottom"
                              buttonContent={
                                <div
                                  role="button"
                                  className="action-item"
                                  onClick={() =>
                                    GoDetails(
                                      item.integrationLogId,
                                      item.logEntityType,
                                      item.startDate,
                                      item.integrationTypeName,
                                      item.integrationTypeEntityName,
                                      item.integrationTypeEntityType
                                    )
                                  }
                                >
                                  <BsInfoCircle color="#0F7EAA" size={24} />
                                </div>
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Col md={12}>
                  {paging && (
                    <BrainzPagination
                      setQueryParams={setQueryPaginationParams}
                      paging={paging}
                    />
                  )}
                </Col>
              </TableSyncDataStyles>
            </>
          ) : (
            <Row style={{ margin: "auto" }}>
              <NoResults clearFilterFunction={ClearFilters} />
            </Row>
          )
        ) : (
          <PageLoader />
        )}
      </Row>

      {showModalInformationError && (
        <InformationModal
          show={showModalInformationError}
          onHide={handleModalInformationErrorClose}
          title="Erro ao integrar"
          subTitle={t("errors." + errorInformationCode)}
          confirmAction={handleModalInformationErrorClose}
          confirmText="Fechar"
          principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
        />
      )}
    </div>
  );
}

export default Logs;
